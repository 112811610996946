import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styles from "./Items.module.css";
import { setName, clearItemBeingMade, getItemBeingCreated, AddCatagory, addNewItem, deleteItem, fetchItems, getCatagories, getItemLoadStatus, getitemsError, getLoadedItem, patchItem, RemoveCatagory, selectItems, SetItem, SetCatagory, setIneqValue, getIneqValue, setItemAmount, setItemNumber, setSize, setCost, setDateOfCost, setPar, setDescription, setOrderValue, setItemBeingCreated } from './itemSlice';
import { fetchVendors, getLoadedVendor, getVendorLoadStatus, getVendorsError, selectVendors, setVendor } from '../vendors/vendorSlice';
import { fetchReportingGroups, getLoadedRG, getreportingGroupsError, getRGLoadStatus, selectReportingGroups, setRG } from '../ReportingGroups/reportingGroupSlice';

const InputField = React.memo(({
    label,
    id,
    value,
    onChange,
    type = "text",
    tabIndex
}) => {
    const inputRef = useRef(null);

    const handleChange = (event) => {
        const { selectionStart } = event.target;

        // Call the parent's onChange handler
        onChange(event);

        // Only handle caret position for text inputs
        if (type === "text") {
            // Use setTimeout to ensure the DOM has updated
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.setSelectionRange(selectionStart, selectionStart);
                }
            }, 0);
        }
    };

    return (
        <label className={styles.field}>
            <span className={styles.inpLabel}>{label}</span>
            <input
                type={type}
                autoComplete="off"
                id={id}
                ref={inputRef}
                value={value || ''}
                onChange={handleChange}
                className={styles.defaultInp}
                tabIndex={tabIndex}
            />
        </label>
    );
});

function ItemSetup() {
    const dispatch = useDispatch();
    const [toggleShowCreate, setToggleShowCreate] = useState(false);
    const [toggleShowEdit, setToggleShowEdit] = useState(false);
    const [toggle, setToggle] = useState(false);

    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }
    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);
    const rgCatagories = useSelector(getCatagories);
    const itemBeingCreated = useSelector(getItemBeingCreated);
    const inequalValue = useSelector(getIneqValue);

    const vendors = useSelector(selectVendors);
    const vendorLoading = useSelector(getVendorLoadStatus);
    const loadedVendor = useSelector(getLoadedVendor);

    const reportingGroups = useSelector(selectReportingGroups);
    const rgLoading = useSelector(getRGLoadStatus);


    const [confirmDeleteItem, setConfirmDeleteItem] = useState(null);



    const selectItemInputRef = useRef();

    // Add screen width state
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    // Add resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (loading !== 'Done') {
            dispatch(fetchItems({ org_id: user.org_id, viewingLocation: user?.viewingLocation }));
        }
    }, [loading, dispatch, user.org_id, user.viewingLocation]);

    useEffect(() => {
        if (vendorLoading !== 'Done') {
            dispatch(fetchVendors({ org_id: user.org_id, location: user?.viewingLocation }));
        }
    }, [vendorLoading, dispatch, user.org_id, user.viewingLocation]);

    useEffect(() => {
        if (rgLoading !== 'Done') {
            dispatch(fetchReportingGroups({ org_id: user.org_id, location: user?.viewingLocation }));
        }
    }, [rgLoading, dispatch, user.org_id, user.viewingLocation]);

    const getPageWrapperClassName = useCallback(() => {
        const baseClass = styles.pageWrapper;
        const scrollClass = (toggleShowCreate === 'create' || toggleShowEdit === 'edit') ? styles.pageWrapperScroll : '';
        return `${baseClass} ${scrollClass}`.trim();
    }, [toggleShowCreate, toggleShowEdit]);

    const hidePopup = useCallback(() => {
        document.getElementById("popupMessage").style.visibility = "hidden";
        document.getElementById("popupBackground").style.visibility = "hidden";

        document.getElementById("popupMessage").style.display = "none";
        document.getElementById("popupBackground").style.display = "none";

    }, []);


    const showPopup = useCallback(() => {
        document.getElementById("popupBackground").style.visibility = "visible";
        document.getElementById("popupMessage").style.visibility = 'visible';

        document.getElementById("popupMessage").style.display = "block";
        document.getElementById("popupBackground").style.display = "block";

    }, []);





    const pageClickEvent = useCallback((e) => {
        let parent = findParent(e.target);
        if (parent.id === "rgChipArea" || parent.id === "outsidePopup") {
            // do nothing
        } else if (parent.tagName === 'BODY') {
            setToggle(false);
        }
    }, []);

    const findParent = useCallback((element) => {
        if (element.parentNode) {
            if (element.id === "rgChipArea" || element.id === "outsidePopup" || element.id === "reportingGroupArea") {
                return element;
            } else {
                return element.tagName === 'BODY' ? element : findParent(element.parentNode);
            }
        }
        return element;
    }, []);
 

    const settingToggle1 = useCallback((value) => {
        if (toggleShowCreate == 'create') {
            setToggle1('');
            document.getElementById("titleText").textContent = "Creating Items";
        } else {
            if (toggleShowEdit == 'edit') {
                clearDataFromFields();
                clearSelects();
                dispatch(clearVendorBeingMade());
            }
            document.getElementById("titleText").textContent = "Editing Items";
            setToggle1(value);
        }
        setToggle2('');

        if (value == 'create') {
            document.getElementById("titleText").textContent = "Creating Items";
        }
    }, [toggleShowCreate, toggleShowEdit, dispatch]);




    const settingToggle2 = useCallback((value) => {
        if (toggleShowEdit === 'edit') {
            setToggleShowEdit('');
        } else {
            setToggleShowEdit(value);
        }
        setToggleShowCreate('');
    }, [toggleShowEdit]);


    const reportingGroupSelectChange = useCallback((catagory, option) => {
        if (option === 'add') {
            dispatch(AddCatagory(catagory));
        } else {
            dispatch(RemoveCatagory(catagory));
        }
    }, [dispatch]);

    const renderNames = useCallback(() => {
        if (rgCatagories) {
            let names = rgCatagories.map(a => a.value);
            return names.join(', ');
        }
        return "";
    }, [rgCatagories]);

    const vendorSelectChange = useCallback((evt) => {
        dispatch(setVendor(evt));
    }, [dispatch]);

    const selectChange = useCallback((evt) => {
        clearSelects();
        dispatch(setItemBeingCreated(evt));
        if (toggleShowEdit !== 'edit') {
            settingToggle2('edit');
        }
        dispatch(SetItem(evt));
        dispatch(setVendor(evt.vendor));
        dispatch(SetCatagory(evt.reportingGroup));
        dispatch(setIneqValue(evt.orderTriggerIneq ? { option: evt?.orderTriggerIneq } : { option: '-' }));
        hidePopup();

    }, [dispatch, toggleShowEdit, settingToggle2]);





    const clearSelects = useCallback(() => {
        let blankEntry = { label: '', value: '' };
        dispatch(setIneqValue({ option: '-' }));
        dispatch(setVendor(blankEntry));
        dispatch(setRG(blankEntry));
        dispatch(SetItem(blankEntry));
        dispatch(SetCatagory([]));
        dispatch(clearItemBeingMade());
    }, [dispatch]);




    const onCancelClicked = useCallback(() => {
        setToggleShowEdit(false);
        setToggleShowCreate(false);
        clearSelects();
        hidePopup();
    }, []);
    const onDeleteClicked = useCallback((item) => {
        setConfirmDeleteItem(item);
    }, []);

    const confirmDelete = useCallback(() => {
        if (confirmDeleteItem) {
            dispatch(deleteItem(confirmDeleteItem)).then(() => {
                clearSelects();
                dispatch(fetchItems({ org_id: user.org_id, location: user?.viewingLocation }));
                onCancelClicked();
                setConfirmDeleteItem(null);
            });
        }
    }, [confirmDeleteItem, dispatch, user.org_id, user.viewingLocation, clearSelects, onCancelClicked]);




    const onSaveClicked = useCallback(() => {
        let newItem = {
            ...itemBeingCreated,
            vendor: loadedVendor,
            orderTriggerIneq: inequalValue,
            reportingGroup: rgCatagories,
            viewingLocation: user.viewingLocation,
            org_id: user.org_id
        };

        if (toggleShowEdit === 'edit') {
            newItem._id = loadedItem._id;

            if (items?.some(e => e.itemName === newItem.itemName)) {

                showPopup()

            } else {
                dispatch(patchItem(newItem));
            }

        } else {
            if (items?.some(e => e.itemName === newItem.itemName)) {

                showPopup()

            } else {
                dispatch(addNewItem(newItem));
            }
        }

        dispatch(fetchItems({ org_id: user.org_id, location: user?.viewingLocation }));
        settingToggle2('edit');
        dispatch(clearItemBeingMade());
        clearSelects();
    }, [itemBeingCreated, loadedVendor, inequalValue, rgCatagories, user, toggleShowEdit, loadedItem, items, dispatch]);




    return (
        <div className={getPageWrapperClassName()} onClick={pageClickEvent}>

            <div className={styles.modal} id="popupBackground" onClick={hidePopup} style={{ visibility: 'hidden' }}>
                <div className={styles.modalContent} id="popupMessage" onClick={(e) => e.stopPropagation()}>
                    <h3>Item Already Exists</h3>
                    <p>An item with this name already exists, please use a different one.</p>
                    <div className={styles.modalButtons}>
                        <button onClick={hidePopup}>OK</button>
                    </div>
                </div>
            </div>


            {confirmDeleteItem && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>Confirm Delete</h3>
                        <p>Are you sure you want to delete the item "{confirmDeleteItem.itemName}"?</p>
                        <div className={styles.modalButtons}>
                            <button onClick={() => setConfirmDeleteItem(null)}>Cancel</button>
                            <button onClick={confirmDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            )}


            <div className={styles.userForm}>
                <div className={styles.userFormContent} style={{ width: '99%' }}>
                    <h3 id="titleText" style={{ textAlign: 'left' }} className={styles.existingUser}>Items</h3>

                    <div className={styles.selectandbuttonwrapper}>
                        <div className={styles.selectWrapper}>
                            <Select
                                id="itemSelect"
                                ref={selectItemInputRef}
                                value={loadedItem}
                                className={styles.itemSelect}
                                onChange={selectChange}
                                options={items}
                                getOptionLabel={(option) => option.itemName}
                                getOptionValue={(option) => option.itemName}
                            />
                        </div>

                        <div className={styles.createItemWrapper}>
                            <button onClick={() => settingToggle1("create")} className={styles.formBtn3}>
                                Create Item
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {(toggleShowCreate === 'create' || toggleShowEdit === 'edit') && (
                <>
                    <div className={styles.itemInfoSection}>
                        <h4 className={styles.contactInfo} style={{ textAlign: 'left' }}>Item Info</h4>
                        <div className={styles.flexBox}>
                            <div className={styles.leftCol}>
                                <InputField
                                    label="Name"
                                    id="displayName"
                                    value={itemBeingCreated?.itemName}
                                    onChange={(evt) => dispatch(setName(evt.target.value))}
                                    tabIndex={isMobile ? 2 : 2}
                                />
                                <InputField
                                    label="Item #"
                                    id="itemNumber"
                                    type="number"
                                    value={itemBeingCreated?.itemNumber}
                                    onChange={(evt) => dispatch(setItemNumber(evt.target.value))}
                                    tabIndex={isMobile ? 3 : 4}
                                />
                                <InputField
                                    label="Size"
                                    id="Size"
                                    value={itemBeingCreated?.size}
                                    onChange={(evt) => dispatch(setSize(evt.target.value))}
                                    tabIndex={isMobile ? 4 : 6}
                                />
                            </div>

                            <div className={styles.rightCol}>
                                <InputField
                                    label="Cost"
                                    id="Cost"
                                    value={itemBeingCreated?.cost}
                                    onChange={(evt) => dispatch(setCost(evt.target.value))}
                                    type="number"
                                    tabIndex={isMobile ? 5 : 3}
                                />
                                <InputField
                                    label="Par"
                                    id="Par"
                                    value={itemBeingCreated?.par}
                                    onChange={(evt) => dispatch(setPar(evt.target.value))}
                                    type="number"
                                    tabIndex={isMobile ? 6 : 5}
                                />
                                <div className={styles.field}>
                                    <span className={styles.inpLabel}>Vendor</span>
                                    {vendorLoading === 'Done' && (
                                        <div className={styles.selectWrapper}>
                                            <Select
                                                id="vendorSelect"
                                                value={loadedVendor}
                                                options={vendors}
                                                className={styles.vendorSelect}
                                                onChange={vendorSelectChange}
                                                getOptionLabel={(option) => option.company}
                                                getOptionValue={(option) => option.company}
                                                tabIndex={isMobile ? 7 : 7}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.blackLineDiv}></div>

                    <div className={styles.bottomSection}>
                        <div className={styles.reportingGroupSection}>
                            <h4 className={styles.reportingGroupText} style={{ textAlign: 'left' }}>Reporting Group</h4>
                            <button
                                id="reportingGroupArea"
                                onClick={() => setToggle(!toggle)}
                                className={styles.fakeSelect}
                            >
                                {renderNames()}
                            </button>
                            {toggle && (
                                <div id="outsidePopup" className={styles.outsidePopup}>
                                    <div className={styles.optionArea}>
                                        {rgLoading === 'Done' && reportingGroups.map((group, index) => (
                                            <div key={index}>
                                                <div>{group.displayName}</div>
                                                {group?.subcatagories?.map((catagory, index2) => (
                                                    <div
                                                        key={index2}
                                                        className={styles.subCategory}
                                                        onClick={() => reportingGroupSelectChange(catagory, 'add')}
                                                    >
                                                        {catagory.value}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div id="rgChipArea" className={styles.selectedArea}>
                                {rgCatagories.map((catagory, index3) => (
                                    <div
                                        key={index3}
                                        className={styles.catagoryItem}
                                        onClick={() => reportingGroupSelectChange(catagory, 'remove')}
                                    >
                                        {catagory.value} <span>X</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.formBtnDiv} style={{ marginTop: '11%' }}>
                            <button
                                onClick={() => onDeleteClicked(loadedItem)}
                                className={styles.formBtn}
                                tabIndex={8}  // Eighth tab stop
                            >
                                Delete
    </button>
                            <button
                                onClick={onCancelClicked}
                                className={styles.formBtn}
                                tabIndex={9}  // Ninth tab stop
                            >
                                Cancel
    </button>
                            <button
                                onClick={onSaveClicked}
                                className={styles.formBtn2}
                                tabIndex={10}  // Tenth tab stop
                            >
                                {toggleShowEdit === 'edit' ? 'Save' : 'Create'}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
export default ItemSetup;