import createHistory from 'history/createBrowserHistory.js';
import { default as React, useEffect, useRef, useState } from 'react';
import { Router } from 'react-router';
import { Route, Switch } from "react-router-dom";
import './App.css';
import Header from './Components/Header.js';
import SideBar from './Components/SideBar.js';
import CreateForm from './Pages/formBuilder/createForm.js';
import ViewForm from './Pages/formBuilder/viewForm.js';
import ItemPage from './Pages/forms/items/Items.js';
import JobPage from './Pages/forms/JobPositions/JobPosition.js';
import LocationPage from './Pages/forms/Locations/location.js';
import Callbackpage from './Pages/forms/loginPage/callback.js';
import LoginForm from './Pages/forms/loginPage/login.js';
import ReportingGroupPage from './Pages/forms/ReportingGroups/ReportingGroups.js';
import UserPage from './Pages/forms/users/Users.js';
import VendorPage from './Pages/forms/vendors/vendor.js';
import PurchaseRequest from './Pages/purchaseRequest/purchaseRequest.js';
import CreateTask from './Pages/taskBuilder/createTask.js';
import ViewTask from './Pages/taskBuilder/viewTasks.js';
import UserDashboard from './Pages/userDashboard/userDashboard.js';
import UserDashboardAdmin from './Pages/userDashboardAdmin/userDashboardAdmin.js';
import Notifications from './Pages/forms/notifications/notification';
import ViewNotification from './Pages/forms/notifications/viewNotification'
import MaintenanceRequest from './Pages/maintenanceRequest/maintenanceRequest'
import SpeechBubble from './Components/ai-chatbot/chatbot'
import { useLocation } from 'react-router-dom';
// Replace the existing user retrieval code with this safer version
const getUserFromStorage = () => {
  try {
    const userStr = localStorage.getItem('user');
    if (!userStr) return '';

    const parsedData = JSON.parse(userStr);


    console.log("User in retrieval")

    // Check if login failed
    if (parsedData === 'Login failed') {
      return parsedData;
    }

    // Return first user if it's an array, otherwise return the parsed data
    return Array.isArray(parsedData) ? parsedData[0] : parsedData;
  } catch (error) {
    console.error('Error parsing user data:', error);
    localStorage.removeItem('user'); // Clean up invalid data
    return '';
  }
};


const history = createHistory({ forceRefresh: false });

var dataGoingToCalendar = {
  style: { input: { left: '58%', top: '62%', display: 'flex' }, calendar: { left: '61%', display: 'block' } },
  dayToLimitTo: 'Monday'
}

function App() {
  const touchStartRef = useRef({ x: 0, y: 0 });
  console.log("start of app reached")

  //localStorage.clear()


  const user = getUserFromStorage();


  console.log("User :", user)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isAuthenticated, setisAutheticated] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleTouchStart = (e) => {
      touchStartRef.current = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    };

    const handleTouchMove = (e) => {
      const deltaX = e.touches[0].clientX - touchStartRef.current.x;
      const deltaY = e.touches[0].clientY - touchStartRef.current.y;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const renderWelcomePage = () => {
    const renderLogo = () => {
      if (user && user !== "Login failed") {
        return <img className='logo2' src={require("./Assets/euWelcomeTr.png")} alt="Welcome" />;
      } else {
        return <img className='logo2 guest-logo' src={require("./Assets/euWelcomeTr.png")} alt="Welcome Guest" />;
      }
    };

    const renderMobileLogo = () => {
      return <img className='logo2' style={{ marginLeft: '25%' }} src={require("./Assets/euWelcome2.png")} alt="Welcome" />;

    };




    if (screenWidth > 1200) {
      return (
        <div className="welcome-large">
          <div style={{ width: '75%', margin: 'auto' }}>

            {renderLogo()}

          </div>
        </div>
      );
    } else if (screenWidth > 768) {
      return (
        <div className="welcome-medium">
          {renderLogo()}
        </div>
      );
    } else {
      return (
        <div className="welcome-small">
          {renderMobileLogo()}
        </div>
      );
    }
  };

  return (
    <Router history={history}>
      <div className='background1'>
        <div className="App" >
          <SideBar className="hideFromMobile" />
          <div className='mainArea'>
            <Header className="hideFromMobile" />
            <Switch>
              <Route path="/Setup_Jobs">
                <JobPage />
              </Route>
              <Route path="/Setup_Users">
                <UserPage />
              </Route>
              <Route path="/Setup_Locations">
                <LocationPage />
              </Route>
              <Route path="/Setup_Items">
                <ItemPage />
              </Route>
              <Route path="/Setup_Vendors">
                <VendorPage />
              </Route>
              <Route path="/Create_Form">
                <CreateForm />
              </Route>
              <Route path="/Setup_ReportingGroups">
                <ReportingGroupPage />
              </Route>
              <Route path="/Create_Task">
                <CreateTask />
              </Route>
              <Route path="/View_Task">
                <ViewTask />
              </Route>
              <Route path="/Purchase_Request">
                <PurchaseRequest />
              </Route>
              <Route path="/View_Form">
                <ViewForm />
              </Route>
              <Route path="/Maintenance_Request">
                <MaintenanceRequest />
              </Route>
              <Route path="/profile">
                <LoginForm />
              </Route>
              <Route path="/Notification">
                <Notifications />
              </Route>
              <Route path="/User_Dashboard">
                <UserDashboard />
              </Route>
              <Route path="/User_Dashboard_Admin">
                <UserDashboardAdmin />
              </Route>
              <Route path="/view_notification">
                <ViewNotification />
              </Route>
              <Route path="/callback">
                <Callbackpage />
              </Route>
              <Route exact path="/">
                <div className='welcomeText'>
                  {renderWelcomePage()}
                </div>
              </Route>
              <Route path="*">
                <div className='welcomeText'>
                  <img className='logo2' src={require("./Assets/euWelcomeTr.png")} alt="Welcome" />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
        {/*isHomePage && (
          <SpeechBubble
            title="Chat with us!"
            initialMessage="Hello! How can we assist you today?"
          />
        )*/}
      </div>
    </Router>
  );
}

export default App;