
import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addNewItem, deleteItem, fetchItems, getItemLoadStatus, getLoadedItem, getitemsError, patchItem, selectItems } from '../forms/items/itemSlice';
import { fetchUserSubmittedTask, getAdminLoadStatus, getUserSubmittedTasks } from '../userDashboardAdmin/userDashboardAdminSlice';
import styles from "./userDashboard.module.css";
import { removeFieldFromForm, changeItemInField, addRowToForm, settingPendingQuantity, setDashboardSummary, setSelectedJob, testScheduler2, setOrderValue, setAdminReviewCheckbox, addUserSubmittedTask, fetchDashboardState, getCurrentView, getLoadStatus, getOneFile, selectuserDashboard, setPageView, setTask, setUserResponseAnswerForDataEntry, updateCheckboxField, updateQuantityField, updateResponseField, uploadingFile, setRequestTypeToGenerate } from './userDashboardSlice';
import Select from 'react-select';

import { useParams } from 'react-router';
import { patchTask, fetchTasks, getTaskLoadStatus, selectTasks } from '../taskBuilder/taskSlice';
import { selectforms, patchFormSection, initializeJobsAndLoadTasks, getFormSectionsAssignedToActiveJob, patchSubmissionOfSection, patchForm, getTrackedTasksReq, getTrackedTasks, getFormSectionsReportingToActiveUser, getFormSectionsAssignedToActiveUser, selectForms, getReportingSections } from '../formBuilder/formSlice'
import { createPendingPurchase } from '../purchaseRequest/pendingPurchase.slice'
import { MdOutlineNotificationsActive } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";

import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const BackupStatusModal = ({ status, message, onClose }) => (
    <div className={styles.modal}>
        <div className={styles.modalContent}>
            <h3>Backup Status</h3>
            <p>{status === 'success' ? 'Backup completed successfully!' : 'Backup failed'}</p>
            <p>{message}</p>
            <div className={styles.modalButtons}>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    </div>
);

const deviceDetection = {
    // Check if device is mobile (including tablets)
    isMobile: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            // Include tablets in mobile detection
            /Tablet|iPad/i.test(navigator.userAgent);
    },

    // Check if device is specifically a tablet
    isTablet: () => {
        return /Tablet|iPad/i.test(navigator.userAgent) ||
            // Alternative method using screen size
            (deviceDetection.isMobile() && Math.min(window.screen.width, window.screen.height) >= 768);
    },

    // Check if device is specifically a smartphone
    isSmartphone: () => {
        return deviceDetection.isMobile() && !deviceDetection.isTablet();
    },

    // Check if device is desktop
    isDesktop: () => {
        return !deviceDetection.isMobile();
    },

    // Check orientation
    isLandscape: () => {
        // Primary method using window.matchMedia
        const mediaQuery = window.matchMedia('(orientation: landscape)');

        // Fallback to window dimensions if matchMedia is not supported
        if (!mediaQuery.matches && window.screen) {
            return window.screen.width > window.screen.height;
        }

        return mediaQuery.matches;
    },

    // Get device type and orientation state
    getDeviceState: () => {
        return {
            isMobile: deviceDetection.isMobile(),
            isTablet: deviceDetection.isTablet(),
            isSmartphone: deviceDetection.isSmartphone(),
            isDesktop: deviceDetection.isDesktop(),
            isLandscape: deviceDetection.isLandscape()
        };
    }
};




function UserDashboard(props) {
    //  const dispatch = useDispatch();
    const history = useHistory();
    const dispatch = useDispatch();


    const forms = useSelector(selectForms);


    // const { user, isAuthenticated, isLoading } = useAuth0();
    var user

    try {
        user = JSON.parse(localStorage.getItem('user'))[0];
    }
    catch {
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}`
    }

    // console.log("CHEKC USER: ", user)

    const tasks = useSelector(selectTasks);
    const userSubmittedTasks = useSelector(getReportingSections);
    const trackedTasks = useSelector(getTrackedTasks);
    const [backupStatus, setBackupStatus] = useState({ show: false, status: '', message: '' });

    // console.log("NEW shut :", userSubmittedTasks)
    const loadingTasks = useSelector(getTaskLoadStatus);
    const userDashboard = useSelector(selectuserDashboard);
    const loadingDashboard = useSelector(getLoadStatus);
    const loadingAdminDashboard = useSelector(getAdminLoadStatus)

    const currentView = useSelector(getCurrentView)

    const params = useParams()

    const items = useSelector(selectItems);
    const loading = useSelector(getItemLoadStatus);
    const itemsError = useSelector(getitemsError);
    const loadedItem = useSelector(getLoadedItem);

    const [confirmResetForm, setConfirmResetForm] = useState(null);


    const onResetClicked = useCallback((data) => {
        //    console.log("Data check  " , data)
        setConfirmResetForm(data);

    }, []);


    const confirmReset = useCallback(() => {
        if (confirmResetForm) {

            ResetSubmission(confirmResetForm)
            setConfirmResetForm(null)
            /*
            dispatch(deleteUser(confirmDeleteUser)).then(() => {
                clearDataFromFields();
                dispatch(fetchUsers({ org_id: user.org_id, location_id: user.viewingLocation }));
                onCancelClicked();
                setConfirmDeleteUser(null);
            });*/
        }
    }, [confirmResetForm, dispatch, user.org_id, user.viewingLocation, clearDataFromFields, onCancelClicked]);




    const [canViewPermission, setViewPermission] = useState(false);

    console.log("canViewPermission", canViewPermission)

    const getVendorItems = (summary, companyName) => {
        if (!summary || !companyName) return [];
        return summary[companyName]?.items || summary[companyName] || [];
    };

    const getVendorInfo = (summary, companyName) => {
        const items = getVendorItems(summary, companyName);
        return items[0]?.vendor || {};
    };

    const hasSummaryItems = (summary) => {
        if (!summary) return false;

        // If summary is an array
        if (Array.isArray(summary)) {
            return summary.length > 0;
        }

        // If summary is an object with vendor keys
        for (const vendorName in summary) {
            const items = summary[vendorName]?.items || summary[vendorName];
            if (Array.isArray(items) && items.length > 0) {
                return true;
            }
        }

        return false;
    };


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.keyCode === 13 || event.key === 'Enter') {
                // Remove focus from active element
                if (document.activeElement) {
                    document.activeElement.blur();
                }

                // Alternative more aggressive approach if needed:
                // document.querySelectorAll('input, button, select, textarea')
                //     .forEach(element => element.blur());
            }
        };

        window.addEventListener('keyup', handleKeyPress);

        return () => {
            window.removeEventListener('keyup', handleKeyPress);
        };
    }, []);




    useEffect(() => {

        for (let i = 0; i < user.jobPosition.length; i++) {
            const jobPosition = user.jobPosition[i];

            jobPosition.permissions.forEach(permission => {
                if (permission == 'View Form Submissions') {
                    setViewPermission(true)
                }
            });

        }
    }, [])

    //Error/issue in the pattern here sedtting up userDashboard
    //The select call to  get the dashboard state does not change the loading status
    ///typically here we fetch data and its  passed back through a extra reducer that updates the load state
    //but i dont really have data to return, unless here is where we query for tasks assigned to the user

    var userResponseOptions = [{ label: 'Current Quantity', value: 'Current Quantity' }]
    var actionOptions = [{ label: 'Order', value: 'Order' }]
    var boolOptions = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]

    console.log("Pulled params : ", params)

    var date = new Date();

    useEffect(() => {

        if (loadingDashboard) {
            //  console.log("fetching vendors")
            dispatch(fetchDashboardState())
        }
    }, [loadingDashboard, dispatch])

    useEffect(() => {

        if (loadingAdminDashboard) {
            //   console.log("fetching user submissions",)
            dispatch(fetchUserSubmittedTask({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
        }
    }, [loadingAdminDashboard, dispatch])

    useEffect(() => {

        // console.log("fetching initializeJobsAndLoadTasks",)

        console.log("User here : ", user)
        dispatch(initializeJobsAndLoadTasks({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
    }, [dispatch])

    useEffect(() => {

        if (loadingAdminDashboard) {
            //   console.log("fetching getTrackedTasksReq",)
            dispatch(getTrackedTasksReq({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
        }
    }, [loadingAdminDashboard, dispatch])

    useEffect(() => {

        if (loadingAdminDashboard) {
            //   console.log("fetching user submissions",)
            dispatch(getFormSectionsAssignedToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition, today: new Date().toLocaleString('en-us', { weekday: 'long' }) }))
        }
    }, [loadingAdminDashboard, dispatch])

    useEffect(() => {

        if (loadingAdminDashboard) {
            //    console.log("fetching user submissions",)
            dispatch(getFormSectionsReportingToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition, today: new Date().toLocaleString('en-us', { weekday: 'long' }) }))
        }
    }, [loadingAdminDashboard, dispatch])

    useEffect(() => {

        //    console.log("Loading tasks w/ day ;", new Date().toLocaleString('en-us', { weekday: 'long' }))
        if (loadingTasks != 'Done') {
            //  console.log("fetching vendors")
            dispatch(fetchTasks({ org_id: user.org_id, location_id: user.viewingLocation, user_id: user._id, today: new Date().toLocaleString('en-us', { weekday: 'long' }), jobPosition: user?.jobPosition }))
            //    dispatch(setLoadStatus(true))
        }
    }, [loadingTasks, dispatch])

    console.log("Pulled tasks : ", tasks)

    useEffect(() => {
        //  console.log("item effect reached")

        if (loading != 'Done') {
            dispatch(fetchItems({ org_id: user.org_id, viewingLocation: user?.viewingLocation }))
        }
    }, [loading, dispatch])

    // Add the new useEffect hook here
    useEffect(() => {
        // This will trigger a re-render whenever the userDashboard.summary state changes
    }, [userDashboard.summary]);
    console.log("pulled dashboard :", userDashboard, "  Load status : ", loadingDashboard, userDashboard?.loadedTask?.task?.fields)

    return (

        <>

            <div id="popupBackground" onClick={() => { hidePopup() }} className={styles.popupBackground}>
            </div>

            <div id="popupMessage" className={styles.popupMessage} onClick={() => { hidePopup() }}>
                <span>Please answer all fields in the row before submitting.</span>



                <div style={{ marginTop: '10%' }}>

                    <button className={styles.formBtn} onClick={() => { hidePopup() }} >Ok</button>
                </div>

            </div>

            {confirmResetForm && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h3>Confirm Reset</h3>
                        <p>Are you sure you want to reset the form?"{confirmResetForm.form.name}"?</p>
                        <div className={styles.modalButtons}>
                            <button onClick={() => setConfirmResetForm(null)}>Cancel</button>
                            <button onClick={() => backupSubmission(confirmResetForm)}>Backup data</button>

                            <button onClick={confirmReset}>Reset</button>
                        </div>
                    </div>
                </div>
            )}
            {backupStatus.show && (
                <BackupStatusModal
                    status={backupStatus.status}
                    onClose={() => setBackupStatus({ show: false, status: '', message: '' })}
                />
            )}




            <div className={styles.pageWrapper} style={
                currentView === 'userSubmission' || currentView === 'task'
                    ? {
                        overflowY: 'scroll',
                        paddingLeft: '0vw',
                        width: '100vw',
                        height: '82vh',
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxWidth: '55vw',
                        paddingRight: '28vw',
                        marginLeft: '13vw',
                    }
                    : currentView === 'summary' ? {

                        overflowY: 'scroll',
                        paddingLeft: '0vw',
                        width: '100vw',
                        height: '82vh',
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxWidth: '92vw',
                        paddingRight: '3vw',
                        marginLeft: '9vw',
                        paddingBottom: '10%'


                    } : { overflowY: 'scroll' }
            }>
                {currentView != "task" && <div>

                </div>}

                <div className={styles.dynamicFormSection} style={{ width: '100%', display: 'contents' }}>

                    <div>
                        <div style={{ display: 'flex' }}>

                        </div>
                    </div>

                    {(currentView != "task" && currentView != 'userSubmission' && currentView != 'summary' && currentView != 'reviewingTask' && currentView != 'trackedTask') &&
                        <div style={{ width: '98%', marginTop: '4%' }}>

                            <div className={styles.notificationWrapper}>
                                <div
                                    className={`${styles.notificationBody} ${userDashboard?.selectedJobPosition === "User form submissions" ? styles.expanded : ''
                                        }`}
                                    style={{
                                        transition: 'all 0.3s ease-in-out',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => setClickedJobPosition("Your assigned Tasks")}
                                >
                                    <div className={styles.midbuffer} style={{ marginTop: '-1.5%', overflowY: 'scroll' }}>
                                        <div className={styles.notifMiddle}>
                                            <span>Your assigned tasks ({forms?.sectionsAssignedToUser?.length ?? 0})</span>

                                            <div style={{
                                                display: userDashboard?.selectedJobPosition === "Your assigned Tasks" ? 'block' : 'none',
                                                paddingBottom: userDashboard?.selectedJobPosition === "Your assigned Tasks" ? '10% !important' : '0% !important',

                                                marginTop: '10px'
                                            }}>
                                                <div className={styles.taskHeader}>
                                                    <span className={styles.spanWidth1}>Form</span>
                                                    <span className={styles.spanWidth2}>Display</span>
                                                    <span className={styles.spanWidth3}>Status</span>
                                                    <span className={styles.spanWidth4} style={{ marginLeft: '-4%' }}>Actions</span>
                                                </div>
                                                {forms?.sectionsAssignedToUser?.map((entry) => (
                                                    <div className={`${styles.taskItem} ${styles.raise}`} key={entry.id}>
                                                        <div className={styles.taskDetails}>
                                                            {(() => {
                                                                const weekDayLetterList = getDaysOfWeekFromData(entry.section?.display);
                                                                let recurrenceText = "";
                                                                let intervalText = "";
                                                                let dateRangeText = "";

                                                                const scheduleInfo = entry.section;

                                                                if (scheduleInfo) {
                                                                    const startDate = new Date(scheduleInfo.startDate);
                                                                    const endDate = new Date(scheduleInfo.endDate);
                                                                    const formatDate = (date) => date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
                                                               
                                                                    switch (scheduleInfo.intervalType) {
                                                                        case "Day":
                                                                            recurrenceText = "Daily";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Week":
                                                                            recurrenceText = weekDayLetterList ? `Weekly on ${weekDayLetterList.join(",")}` : "Weekly";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Month":
                                                                            if (scheduleInfo.monthOption === "cardinal") {
                                                                                recurrenceText = `Monthly on day ${scheduleInfo.dayValueForMonth}`;
                                                                            } else if (scheduleInfo.monthOption === "ordinal") {
                                                                                recurrenceText = `Monthly on the ${scheduleInfo?.ordinalMonthValue?.label} ${scheduleInfo?.monthDayToRepeat?.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Year":
                                                                            if (scheduleInfo.yearOption === "cardinal") {
                                                                                recurrenceText = `Yearly on ${scheduleInfo?.monthValueForYear?.label} ${scheduleInfo?.dayValueForYear}`;
                                                                            } else if (scheduleInfo.yearOption === "ordinal") {
                                                                                recurrenceText = `Yearly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label} of ${scheduleInfo.monthValueForYear.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        default:
                                                                            if (scheduleInfo.frequency.label === "Infinite") {
                                                                                recurrenceText = "Infinite";
                                                                                dateRangeText = `${formatDate(startDate)}${scheduleInfo.endDate ? ` - ${formatDate(new Date(scheduleInfo.endDate))}` : ' - Ongoing'}`;
                                                                            } else {
                                                                                recurrenceText = scheduleInfo.frequency.label;
                                                                                dateRangeText = `${formatDate(startDate)}`;
                                                                            }
                                                                    }

                                                                    if (scheduleInfo.intervalValue && scheduleInfo.intervalValue !== "1") {
                                                                        intervalText = `Every ${scheduleInfo.intervalValue} ${scheduleInfo.intervalType.toLowerCase()}${scheduleInfo.intervalValue !== "1" ? "s" : ""}`;
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        <span className={styles.spanWidthtable1}>{entry.form.name}</span>
                                                                        <span className={styles.spanWidthtable2}>{recurrenceText} {intervalText}</span>
                                                                        <span className={styles.spanWidthtable2}>{dateRangeText}</span>
                                                                    </>
                                                                );
                                                            })()}
                                                        </div>
                                                        <div className={styles.taskStatus}>
                                                            {entry?.section?.submitted !== true && (
                                                                <span>{entry?.section?.submissions?.length > 0 ? entry?.section?.submissions?.length + ' submissions' : 'Awaiting submissions'}</span>
                                                            )}

                                                            {entry?.section?.submitted === true && (
                                                                <span>
                                                                    Awaiting review ({entry?.section?.submissions?.length || 0} {entry?.section?.submissions?.length === 1 ? 'submission' : 'submissions'})
                                                                </span>)}
                                                        </div>
                                                        <div className={styles.taskActions}>
                                                            <button onClick={() => openTask(entry, 'task', entry.section)}>Task</button>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*canViewPermission &&*/
                                <div
                                    className={`${styles.notificationBody} ${userDashboard?.selectedJobPosition === "User form submissions" ? styles.expanded : ''
                                        }`}
                                    style={{
                                        transition: 'all 0.3s ease-in-out',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => setClickedJobPosition("User form submissions")}
                                >
                                    <div className={styles.midbuffer} style={{ marginTop: '-1.5%', overflowY: 'scroll' }} >
                                        <div className={styles.notifMiddle}>
                                            <span> Form submissions ({forms?.trackedTasks?.length})</span>

                                            <div style={{
                                                display: userDashboard?.selectedJobPosition === "User form submissions" ? 'block' : 'none',
                                                marginTop: '10px'
                                            }}>
                                                <div className={styles.taskHeader}>
                                                    <span className={styles.spanWidth1}>Form</span>
                                                    <span className={styles.spanWidth2}>Display</span>
                                                    <span className={styles.spanWidth3}>Status</span>
                                                    <span className={styles.spanWidth4} >Actions</span>
                                                </div>
                                                {forms?.trackedTasks?.map((entry) => (
                                                    <div className={`${styles.taskItem} ${styles.raise}`} key={entry.id}>
                                                        <div className={styles.taskDetails}>
                                                            {(() => {
                                                                const weekDayLetterList = getDaysOfWeekFromData(entry.section?.display);
                                                                let recurrenceText = "";
                                                                let intervalText = "";
                                                                let dateRangeText = "";

                                                                const scheduleInfo = entry.section;

                                                                if (scheduleInfo) {
                                                                    const startDate = new Date(scheduleInfo.startDate);
                                                                    const endDate = new Date(scheduleInfo.endDate);
                                                                    const formatDate = (date) => date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

                                                                    switch (scheduleInfo.intervalType) {
                                                                        case "Day":
                                                                            recurrenceText = "Daily";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Week":
                                                                            recurrenceText = weekDayLetterList ? `Weekly on ${weekDayLetterList.join(",")}` : "Weekly";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Month":
                                                                            if (scheduleInfo.monthOption === "cardinal") {
                                                                                recurrenceText = `Monthly on day ${scheduleInfo.dayValueForMonth}`;
                                                                            } else if (scheduleInfo.monthOption === "ordinal") {
                                                                                recurrenceText = `Monthly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Year":
                                                                            if (scheduleInfo.yearOption === "cardinal") {
                                                                                recurrenceText = `Yearly on ${scheduleInfo.monthValueForYear.label} ${scheduleInfo.dayValueForYear}`;
                                                                            } else if (scheduleInfo.yearOption === "ordinal") {
                                                                                recurrenceText = `Yearly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label} of ${scheduleInfo.monthValueForYear.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        default:
                                                                            recurrenceText = scheduleInfo.frequency.label;
                                                                            dateRangeText = `${formatDate(startDate)}`;
                                                                    }

                                                                    if (scheduleInfo.intervalValue && scheduleInfo.intervalValue !== "1") {
                                                                        intervalText = `Every ${scheduleInfo.intervalValue} ${scheduleInfo.intervalType.toLowerCase()}${scheduleInfo.intervalValue !== "1" ? "s" : ""}`;
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        <span className={styles.spanWidthtable1}>{entry.form.name}</span>
                                                                        <span className={styles.spanWidthtable2}>{recurrenceText} {intervalText}</span>
                                                                        <span className={styles.spanWidthtable2}>{dateRangeText}</span>
                                                                    </>
                                                                );
                                                            })()}
                                                        </div>
                                                        <div className={styles.taskStatus}>
                                                            {entry?.section?.submitted !== true && (
                                                                <span>{entry?.section?.submissions?.length > 0 ? entry?.section?.submissions?.length + ' submissions' : 'Awaiting submissions'}</span>
                                                            )}

                                                            {entry?.section?.submitted === true && (
                                                                <span>
                                                                    Awaiting review ({entry?.section?.submissions?.length || 0} {entry?.section?.submissions?.length === 1 ? 'submission' : 'submissions'})
                                                                </span>)}
                                                        </div>
                                                        <div className={styles.taskActions}>


                                                            <button style={{ marginRight: '2vw' }} onClick={() => backupSubmission(entry)}>Backup </button>

                                                            <button style={{ marginRight: '2vw' }} onClick={() => onResetClicked(entry)}>Reset Data </button>

                                                            <button style={{ marginRight: '2vw' }} onClick={() => download(entry)}>Download</button>

                                                            <button onClick={() => openTask(entry, 'reviewingTask', entry.section)}>View</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {user?.jobPosition && user?.jobPosition?.map((jobPosition, jobIndex) => {
                                const docsLength = forms?.sectionsAssignedToJob[jobPosition.jobTitle]?.docs?.length;

                                return docsLength > 0 ? (
                                    <div
                                        key={jobPosition.id}
                                        className={`${styles.notificationBody} ${userDashboard?.selectedJobPosition?._id === jobPosition?._id ? styles.expanded : ''
                                            }`}
                                        style={{
                                            transition: 'all 0.3s ease-in-out',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setClickedJobPosition(jobPosition)}
                                    >
                                        <div className={styles.notifMiddle}>
                                            <span>{jobPosition.jobTitle}'s Tasks ({docsLength})</span>

                                            <div style={{
                                                display: userDashboard?.selectedJobPosition?._id === jobPosition?._id ? 'block' : 'none',
                                                marginTop: '10px'
                                            }}>
                                                <div className={styles.taskHeader}>
                                                    <span className={styles.spanWidth1}>Form</span>
                                                    <span className={styles.spanWidth2}>Display</span>
                                                    <span className={styles.spanWidth3} style={{ paddingLeft: '1%' }}>Status</span>
                                                    <span className={styles.spanWidth4} style={{ marginLeft: '-4vw' }}>Actions</span>
                                                </div>

                                                {forms?.sectionsAssignedToJob[jobPosition.jobTitle]?.docs.map((entry) => (
                                                    <div key={entry.id} className={`${styles.taskItem} ${styles.raise}`}>
                                                        <div className={styles.taskDetails}>
                                                            {(() => {
                                                                const weekDayLetterList = getDaysOfWeekFromData(entry.section?.display);
                                                                let recurrenceText = "";
                                                                let intervalText = "";
                                                                let dateRangeText = "";

                                                                const scheduleInfo = entry.section

                                                                if (scheduleInfo) {
                                                                    const startDate = new Date(scheduleInfo.startDate);
                                                                    const endDate = new Date(scheduleInfo.endDate);
                                                                    const formatDate = (date) => date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });

                                                                    switch (scheduleInfo.intervalType) {
                                                                        case "Day":
                                                                            recurrenceText = "Daily";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Week":
                                                                            recurrenceText = weekDayLetterList ? `Weekly on ${weekDayLetterList.join(",")}` : "Weekly";
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Month":
                                                                            if (scheduleInfo.monthOption === "cardinal") {
                                                                                recurrenceText = `Monthly on day ${scheduleInfo.dayValueForMonth}`;
                                                                            } else if (scheduleInfo.monthOption === "ordinal") {
                                                                                recurrenceText = `Monthly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        case "Year":
                                                                            if (scheduleInfo.yearOption === "cardinal") {
                                                                                recurrenceText = `Yearly on ${scheduleInfo.monthValueForYear.label} ${scheduleInfo.dayValueForYear}`;
                                                                            } else if (scheduleInfo.yearOption === "ordinal") {
                                                                                recurrenceText = `Yearly on the ${scheduleInfo.ordinalMonthValue.label} ${scheduleInfo.monthDayToRepeat.label} of ${scheduleInfo.monthValueForYear.label}`;
                                                                            }
                                                                            dateRangeText = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                                                                            break;
                                                                        default:
                                                                            recurrenceText = scheduleInfo.frequency.label;
                                                                            dateRangeText = `${formatDate(startDate)}`;
                                                                    }

                                                                    if (scheduleInfo.intervalValue && scheduleInfo.intervalValue !== "1") {
                                                                        intervalText = `Every ${scheduleInfo.intervalValue} ${scheduleInfo.intervalType.toLowerCase()}${scheduleInfo.intervalValue !== "1" ? "s" : ""}`;
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        <span className={styles.spanWidthtable1}>{entry.form.name}</span>
                                                                        <span className={styles.spanWidthtable2}>{recurrenceText} {intervalText}</span>
                                                                        <span className={styles.spanWidthtable2}>{dateRangeText}</span>
                                                                    </>
                                                                );
                                                            })()}

                                                        </div>

                                                        <div className={styles.taskStatus}>
                                                            {entry?.section?.submitted !== true && (
                                                                <span>{entry?.section?.submissions?.length > 0 ? entry?.section?.submissions?.length + ' submissions' : 'Awaiting submissions'}</span>
                                                            )}

                                                            {entry?.section?.submitted === true && (
                                                                <span>
                                                                    Awaiting review ({entry?.section?.submissions?.length || 0} {entry?.section?.submissions?.length === 1 ? 'submission' : 'submissions'})
                                                                </span>)}
                                                        </div>

                                                        <div className={styles.taskActions}>
                                                            <button onClick={() => openTask(entry, 'trackedTask', entry.section)}>
                                                                View
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            })}


                        </div>
                    }



                    {((currentView == "task" || currentView == "reviewingTask") && currentView != 'userSubmission' && currentView != 'summary') && < div style={{ display: 'flex', width: '86%', }}>
                        <div style={{ width: '100vw' }}>


                            {(userDashboard?.loadedTask?.section?.submissions?.length > 0 || userDashboard.summary.length > 0) && (currentView == 'reviewingTask' || currentView == 'task') &&
                                /* THIS IS THE DEFUALT VIEW, WHEN ANY USER VIEWS TASK */

                                <div className={styles.subPageHeaders}>


                                    {userDashboard.summary.length}

                                    <button onClick={() => { resetPage() }}>Back to Dashboard</button>
                                    {(currentView != 'reviewingTask' && currentView != 'task') && <button onClick={() => { openSubPage('task', userDashboard) }}>Form</button>}
                                    {hasSummaryItems(userDashboard.summary) && (
                                        <button
                                            style={{ marginLeft: '5%' }}
                                            onClick={() => { openSubPage('summary', userDashboard) }}
                                        >
                                            Order Summary
                                        </button>
                                    )}

                                </div>

                            }



                            <div className={styles.titleSection}>
                                <span> {userDashboard.loadedTask?.section?.sectionName}</span>
                            </div>

                            {loadingDashboard == false && userDashboard?.loadedTask?.section?.fields?.map((field, index1) => {

                                if (userDashboard?.loadedTask?.section.type == 'Data Entry') {
                                    return (
                                        <div className={styles.DEfields} key={index1} style={{ width: '100%', overflow: field.addedLate ? 'visible' : 'hidden' }}>
                                            {(() => {
                                                const submission = userDashboard?.loadedTask?.section?.submissions;
                                                const latestSubmission = getLatestSubmission(submission, index1);

                                                //console.log("submission data for response:", latestSubmission, index1, latestSubmission?.listToReturn);

                                                const fieldIndexToCheck = index1;
                                                const listToReturn = latestSubmission?.listToReturn ?? {};

                                                const matchingElement = listToReturn[index1];

                                                //console.log("conditional check for response", matchingElement, fieldIndexToCheck, index1, field);

                                                if (matchingElement && matchingElement.fieldIndex === fieldIndexToCheck) {
                                                    return (
                                                        <div className={`${styles.groupDiv} ${styles.responsiveGroupDiv}`}>


                                                            <div className={styles.desktopOnly} style={{ display: 'flex' }}>
                                                                <div className={`${styles.DEfieldSection} ${styles.itemSection}`}>
                                                                    {index1 == 0 && <span>Item</span>}
                                                                    <div className={styles.itemName}>
                                                                        <span>{field.item.itemName}</span>
                                                                    </div>
                                                                </div>
                                                                {field.includePar == true && (
                                                                    <div className={`${styles.DEfieldSection} ${styles.parSection}`}>
                                                                        <div className="checkboxes">
                                                                            <label>{index1 == 0 && <span>Par</span>}</label>
                                                                        </div>
                                                                        <span>{field.parValue}</span>
                                                                    </div>
                                                                )}

                                                                {field.includePar == false && (
                                                                    <div className={`${styles.DEfieldSection} ${styles.parSection}`}>

                                                                    </div>
                                                                )}

                                                                <div className={`${styles.DEfieldSection} ${styles.triggerSection}`}>
                                                                    <div className="checkboxes">
                                                                        <label style={{ display: 'flex' }}>{index1 == 0 && <span style={{ display: 'flex' }}>Trigger Purchase</span>}</label>
                                                                    </div>
                                                                    <div className={styles.triggerIcon}>
                                                                        <span>{field?.triggerPurchase ? <FaCheck /> : <IoClose />}</span>
                                                                    </div>
                                                                </div>
                                                                {field?.userResponse[0] && (
                                                                    <div className={`${styles.DEfieldSection} ${styles.responseSection}`}>
                                                                        {index1 == 0 && <span>{field.userResponse[0].question}</span>}
                                                                        <div className={styles.responseAnswer}>
                                                                            <span>{field.userResponse[0].answer}</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {field?.userResponse?.slice(1).map((obj, index2) => (
                                                                    <div style={{ marginTop: '1vh' }} className={`${styles.DEfieldSection} ${styles.customResponseSection}`} key={index2}>
                                                                        <span>{obj.question}</span>
                                                                        <span disabled onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}>{obj.answer}</span>
                                                                    </div>
                                                                ))}


                                                                {/*canViewPermission == true && field.triggerPurchase == true && (
                                                                    <div className={`${styles.DEfieldSection} ${styles.orderSection}`}>
                                                                        <div className="checkboxes">
                                                                            <label><span>How many to order?</span></label>
                                                                        </div>
                                                                        <div className={styles.orderInput}>
                                                                            <input
                                                                                id={`howMany${index1}`}
                                                                                type='number'
                                                                                value={(() => {
                                                                                    const summary = userDashboard.summary;
                                                                                    if (!summary) return '';

                                                                                    // First check fields array for most up-to-date value
                                                                                    if (field.item?.pendingOrderQuantity) {
                                                                                        return field.item.pendingOrderQuantity;
                                                                                    }

                                                                                    // Then check summary object
                                                                                    if (typeof summary === 'object' && !Array.isArray(summary)) {
                                                                                        for (const vendorName in summary) {
                                                                                            const items = summary[vendorName]?.items || summary[vendorName];
                                                                                            const foundItem = items?.find(item => item._id === field.item._id);
                                                                                            if (foundItem) {
                                                                                                return foundItem.pendingOrderQuantity || '';
                                                                                            }
                                                                                        }
                                                                                    }

                                                                                    // Finally check array format
                                                                                    if (Array.isArray(summary)) {
                                                                                        return summary.find(item => item._id === field.item._id)?.pendingOrderQuantity || '';
                                                                                    }

                                                                                    return '';
                                                                                })()}
                                                                                onChange={(evt) => {
                                                                                    dispatch(settingPendingQuantity({
                                                                                        itemId: field.item._id,
                                                                                        newQuantity: evt.target.value,
                                                                                        vendor: field.item.vendor?.company
                                                                                    }))
                                                                                }}
                                                                                className={styles.defaultInp}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                            )*/}


                                                                {canViewPermission == true && field.triggerPurchase == true &&

                                                                    <div className={`${styles.DEfieldSection} ${styles.orderSection}`}>
                                                                        <div className="checkboxes">
                                                                            <label  ><span>How many to order?</span></label>
                                                                        </div>
                                                                        <div className={styles.orderInput}>
                                                                            <input
                                                                                id={`howMany${index1}`}
                                                                                type='number'
                                                                                value={
                                                                                    userDashboard.loadedTask.section?.cart.length > 0 &&
                                                                                    userDashboard.loadedTask.section?.cart?.find(
                                                                                        (item) => item._id === field.item._id
                                                                                    )?.pendingOrderQuantity || null
                                                                                }
                                                                                className={styles.defaultInp}
                                                                            ></input>
                                                                        </div>
                                                                    </div>
                                                                }


                                                            </div>




                                                            {currentView == 'userSubmission' &&


                                                                <div className={styles.mobileOnly}>
                                                                    <div className={styles.mobileFlexWrapper}>
                                                                        <div className={styles.mobileRow}>
                                                                            <div className={`${styles.DEfieldSection} ${styles.itemSection}`}>
                                                                                <div className={styles.mobileOnly}><span className={styles.spanWidth1} style={{ width: '17vw' }}>Item</span></div>
                                                                                <div className={styles.itemName} className={styles.spandWidth1}  >
                                                                                    <span className={styles.spanWidth1} style={{ width: '17vw' }}>{field.item.itemName}</span>
                                                                                </div>
                                                                            </div>
                                                                            {field.includePar && (
                                                                                <div className={`${styles.DEfieldSection} ${styles.parSection}`}>
                                                                                    <div className="checkboxes">
                                                                                        <label className={styles.mobileOnly}><span className={styles.spanWidth1} style={{ width: '17vw' }}>Par</span></label>
                                                                                    </div>
                                                                                    <span className={styles.spanWidth1} style={{ width: '3vw', minWidth: '3vw', maxWidth: '3vw' }}>{field.parValue}</span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className={styles.mobileRow}>
                                                                            <div className={`${styles.DEfieldSection} ${styles.triggerSection}`}>
                                                                                <div className="checkboxes">
                                                                                    <label className={styles.mobileOnly}><span className={styles.spanWidth1} style={{ width: '17vw' }}>Trigger Purchase</span></label>
                                                                                </div>
                                                                                <div className={styles.triggerIcon} style={{ marginTop: '0px' }}>
                                                                                    <span className={styles.spanWidth1} style={{ width: '17vw' }}>{field?.triggerPurchase ? <FaCheck /> : <IoClose />}</span>
                                                                                </div>
                                                                            </div>
                                                                            {field?.userResponse[0] && (
                                                                                <div className={`${styles.DEfieldSection} ${styles.responseSection}`}>
                                                                                    <div className={styles.mobileOnly}><span>{field.userResponse[0].question}</span></div>
                                                                                    <div className={styles.responseAnswer} style={{ marginTop: '3px' }}>
                                                                                        <span className={styles.spanWidth1} style={{ width: '17vw' }}>{field.userResponse[0].answer}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                        {field?.userResponse?.slice(1, -1).map((obj, index2) => (
                                                                            <div key={index2} className={styles.mobileRow} style={{ maxWidth: '74vw' }}>
                                                                                <div className={`${styles.DEfieldSection} ${styles.customResponseSection}`}>
                                                                                    <span>{obj.question}</span>
                                                                                    <span
                                                                                        disabled
                                                                                        className={styles.defaultInp}
                                                                                        defaultValue={obj.answer}

                                                                                        onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}
                                                                                    ></span>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        <div className={styles.mobileRow} >
                                                                            <div className={`${styles.DEfieldSection} ${styles.orderSection}`}>
                                                                                <div className="checkboxes">
                                                                                    <label className={styles.mobileOnly}><span  >How many to order?</span></label>
                                                                                </div>
                                                                                <div className={styles.orderInput}>
                                                                                    <input id={`howMany${index1}`} type='number' className={styles.defaultInp}></input>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            }





                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className={`${styles.groupDiv} ${styles.responsiveGroupDiv}`}>
                                                            <div className={styles.desktopOnly} style={{ display: 'flex' }}>


                                                                <div className={`${styles.DEfieldSection} ${styles.itemSection}`}>
                                                                    {index1 == 0 && <span>Item</span>}



                                                                    {!field.addedLate && <div>

                                                                        <div className={styles.itemName}>
                                                                            <span>{field.item.itemName}</span>
                                                                        </div>
                                                                    </div>}


                                                                    <div style={{ display: 'ruby', marginTop: '3vh' }}>

                                                                        {field.addedLate &&
                                                                            <div>
                                                                                <button
                                                                                    onClick={() => handleRemove(index1)}
                                                                                >Remove</button>

                                                                            </div>
                                                                        }

                                                                        {field.addedLate && <div>

                                                                            <Select id="itemSelect" value={userDashboard?.loadedTask?.section?.fields.item} onChange={(item) => itemChangedInLateField(item, index1)}
                                                                                options={items}
                                                                                className={styles.itemSelect}
                                                                                style={{ width: '100%', alignContent: 'center', marginBottom: 'auto', marginTop: 'auto', marginTop: '5%', }}
                                                                                getOptionLabel={(option) => option.itemName}
                                                                                getOptionValue={(option) => option.itemName}
                                                                            > </Select>

                                                                        </div>}



                                                                        {field.addedLate && !deviceDetection.isMobile && <div>
                                                                            {loading == 'Done' && (
                                                                                <CustomSelect
                                                                                    id="itemSelect"
                                                                                    value={userDashboard?.loadedTask?.section?.fields.item}
                                                                                    onChange={(item) => itemChangedInLateField(item, index1)}
                                                                                    options={items}
                                                                                    className={styles.itemSelect}
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        alignContent: 'center',
                                                                                        marginBottom: 'auto',
                                                                                        marginTop: 'auto',
                                                                                        marginTop: '5%',
                                                                                    }}
                                                                                    getOptionLabel={(option) => option.itemName}
                                                                                    getOptionValue={(option) => option.itemName}
                                                                                />
                                                                            )}

                                                                        </div>}

                                                                    </div>

                                                                </div>
                                                                {field.includePar == true && (
                                                                    <div className={`${styles.DEfieldSection} ${styles.parSection}`}>
                                                                        <div className="checkboxes">
                                                                            <label>{index1 == 0 && <span>Par</span>}</label>
                                                                        </div>
                                                                        <span>{field.parValue}</span>
                                                                    </div>
                                                                )}
                                                                {field?.userResponse?.map((obj, index2) => (
                                                                    <div>



                                                                        {index2 != 0 &&
                                                                            <div className={`${styles.DEfieldSection} ${styles.customResponseSection}`} key={index2}>
                                                                                <span>{obj.question}</span>
                                                                                <input
                                                                                    className={styles.defaultInp}
                                                                                    defaultValue={obj.answer}
                                                                                    onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}
                                                                                ></input>
                                                                            </div>
                                                                        }



                                                                        {index2 == 0 &&
                                                                            <div className={`${styles.DEfieldSection} ${styles.customResponseSection}`} key={index2}>
                                                                                <span>{obj.question}</span>
                                                                                <input
                                                                                    className={styles.defaultInp}
                                                                                    defaultValue={obj.answer}
                                                                                    type='number'

                                                                                    onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}
                                                                                ></input>
                                                                            </div>


                                                                        }


                                                                    </div>






                                                                ))}
                                                            </div>
                                                            <div className={styles.mobileOnly}>
                                                                <div className={styles.mobileFlexWrapper}>
                                                                    <div className={styles.mobileRow}>
                                                                        <div className={`${styles.DEfieldSection} ${styles.itemSection}`}>
                                                                            <div className={styles.mobileOnly}><span className={styles.spanWidth1} style={{ width: '17vw' }}>Item</span></div>
                                                                            <div className={`${styles.itemName} ${styles.spanWidth1}`} style={{ margin: '0px' }}>
                                                                                <span className={styles.spanWidth1} style={{ width: '17vw', margin: '0px' }}>{field.item.itemName}</span>
                                                                            </div>
                                                                        </div>
                                                                        {field.includePar && (
                                                                            <div className={`${styles.DEfieldSection} ${styles.parSection}`}>
                                                                                <div className="checkboxes">
                                                                                    <label className={styles.mobileOnly}><span className={styles.spanWidth1} style={{ width: '17vw' }}>Par</span></label>
                                                                                </div>
                                                                                <span className={styles.spanWidth1} style={{ width: '17vw' }}>{field.parValue}</span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {field?.userResponse?.map((obj, index2) => (
                                                                        <div key={index2} className={styles.mobileRow} style={{ width: '50vw', margin: 'auto' }}>
                                                                            <div className={`${styles.DEfieldSection} ${styles.customResponseSection}`}>
                                                                                <span>{obj.question}</span>
                                                                                <input

                                                                                    className={styles.defaultInp}
                                                                                    defaultValue={obj.answer}
                                                                                    onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}
                                                                                ></input>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })()}
                                            <div className={styles.submissionInfo}>
                                                {(() => {
                                                    const submission = userDashboard?.loadedTask?.section?.submissions;
                                                    //console.log("sending submission : ", submission);
                                                    const latestSubmission = getLatestSubmission(submission, index1);

                                                    //console.log("response area :", latestSubmission, index1);

                                                    if (latestSubmission?.listToReturn && latestSubmission.listToReturn[index1]) {
                                                        const submissionData = latestSubmission.listToReturn[index1];
                                                        return (
                                                            <div style={{ display: 'inline', textAlign: 'left' }}>
                                                                <span>Submitted: </span>
                                                                <span>{new Date(submissionData.timestamp).toLocaleString()} by </span>
                                                                <span>{submissionData.submittedBy?.username || 'Unknown User'}</span>
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                })()}
                                            </div>
                                        </div>
                                    );
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Response') {
                                    return (
                                        <div className={`${styles.DEfields} ${styles.overflowXAuto}`} key={index1} style={{ margin: '0px', gap: '0px' }}>
                                            <div className={`${styles.wFull} ${styles.mb2} ${styles.responseWrapper}`} >
                                                {field?.responseColumns.map((col, index2) => {
                                                    return (
                                                        <div className={`${styles.flex} ${styles.flexCol} ${styles.mb2}`} style={{ flexDirection: 'column', textAlign: 'left' }} key={index2}>
                                                            <span className={`${styles.textSm} ${styles.mb2}`} style={{ minWidth: '35vw' }}>{col.text}</span>

                                                            {(() => {
                                                                const submission = userDashboard?.loadedTask?.section?.submissions;
                                                                const latestSubmission = getLatestSubmission(submission, index1);

                                                                //console.log("submission data for response:", latestSubmission, index1, latestSubmission?.listToReturn);

                                                                const fieldIndexToCheck = index1;
                                                                const listToReturn = latestSubmission?.listToReturn ?? {};

                                                                const matchingElement = listToReturn[index1];

                                                                //console.log("conditional check for response", matchingElement, fieldIndexToCheck, index1);

                                                                if (matchingElement && matchingElement.fieldIndex === fieldIndexToCheck) {
                                                                    return (
                                                                        <span
                                                                            className={styles.defaultInp}

                                                                            disabled
                                                                            style={{
                                                                                marginLeft: index2 != 0 ? '3%' : '0%',
                                                                                backgroundColor: 'grey',
                                                                                maxWidth: '15vw'
                                                                            }}
                                                                        >{matchingElement.value[index2].userResponse} </span>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <input
                                                                            className={styles.defaultInp}
                                                                            defaultValue={col.userResponse}
                                                                            style={{ maxWidth: '25vw' }}
                                                                            onChange={(evt) => updatingResponseField(evt.target.value, index1, index2)}

                                                                        />
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div>
                                                <div style={{ display: 'inline' }}>
                                                    {(() => {
                                                        const submission = userDashboard?.loadedTask?.section?.submissions;
                                                        //console.log("sending submission : ", submission);
                                                        const latestSubmission = getLatestSubmission(submission, index1);

                                                        //console.log("response area :", latestSubmission, index1);

                                                        if (latestSubmission?.listToReturn && latestSubmission.listToReturn[index1]) {
                                                            const submissionData = latestSubmission.listToReturn[index1];
                                                            return (
                                                                <div style={{ display: 'inline', textAlign: 'left' }}>
                                                                    <span>Submitted: </span>
                                                                    <span>{new Date(submissionData.timestamp).toLocaleString()} by </span>
                                                                    <span>{submissionData.submittedBy?.username || 'Unknown User'}</span>
                                                                </div>
                                                            );
                                                        }

                                                        return null;
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Checklist') {
                                    return (
                                        <div className={`${styles.DEfields} ${styles.overflowXAuto}`} key={index1}>
                                            <div className={`${styles.otherFields} ${styles.wFull}  ${styles.checkboxDescription}`} style={{ display: 'contents' }}>
                                                <div className={`${styles.DEfieldSection} ${styles.wFull}`}>
                                                    <div className={`${styles.mb2}`}>
                                                        <span className={styles.textSm} >{field.description}</span>

                                                        <div className={`${styles.flex} ${styles.flexWrap} ${styles.mt2}`}>
                                                            {field?.answerChoices.map((answer, index2) => {
                                                                const submission = userDashboard?.loadedTask?.section?.submissions?.length > 0
                                                                    ? userDashboard?.loadedTask?.section?.submissions
                                                                    : userDashboard?.loadedTask?.section?.submissions;
                                                                const latestSubmission = getLatestSubmission(submission, index1);
                                                                const listToReturn = latestSubmission?.listToReturn ?? {};
                                                                const matchingElement = listToReturn[index1];

                                                                return (
                                                                    <div key={index2} className={`${styles.mb2} ${styles.mr2} ${styles.displayFix}`}>
                                                                        {matchingElement && matchingElement.fieldIndex === index1 ? (
                                                                            <button
                                                                                className={`${styles.orangeButton} ${styles.responsiveButton}`}
                                                                                style={{
                                                                                    backgroundColor: matchingElement.value === answer.text ? '#ffab3e' : '',
                                                                                }}
                                                                                disabled
                                                                            >
                                                                                {answer.text}
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                className={`${styles.orangeButton} ${styles.responsiveButton}`}
                                                                                style={{
                                                                                    backgroundColor: field.answerChoicesResponse === answer.text ? '#ffab3e' : '',
                                                                                }}
                                                                                onClick={() => updatingCheckboxField(answer.text, index1, index2)}
                                                                            >
                                                                                {answer.text}
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${styles.wFull} ${styles.mt2}`}>
                                                <div className={styles.submissionInfo}>
                                                    {(() => {
                                                        const submission = userDashboard?.loadedTask?.section?.submissions;
                                                        const latestSubmission = getLatestSubmission(submission, index1);

                                                        if (latestSubmission?.listToReturn && latestSubmission.listToReturn[index1]) {
                                                            const submissionData = latestSubmission.listToReturn[index1];
                                                            return (
                                                                <div className={`${styles.textSm} ${styles.mt2}`} style={{ textAlign: 'left' }}>
                                                                    <span>Submitted: </span>
                                                                    <span>{new Date(submissionData.timestamp).toLocaleString()} by </span>
                                                                    <span>{submissionData.submittedBy?.username || 'Unknown User'}</span>
                                                                </div>
                                                            );
                                                        }

                                                        return null;
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Visual') {
                                    return (<div className={styles.DEfields} key={index1} >

                                        {(() => {
                                            const submission = userDashboard?.loadedTask?.section?.submissions;
                                            const latestSubmission = getLatestSubmission(submission, index1);

                                            //console.log("submission data for visual:", latestSubmission, index1, latestSubmission?.listToReturn);

                                            const fieldIndexToCheck = index1;
                                            const listToReturn = latestSubmission?.listToReturn ?? {};

                                            const matchingElement = listToReturn[index1];

                                            //console.log("conditional check for visual", matchingElement, fieldIndexToCheck, index1);

                                            if (matchingElement && matchingElement.fieldIndex === fieldIndexToCheck) {
                                                return (
                                                    <div>
                                                        <div className={styles.otherFields}>
                                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                                <div style={{ marginTop: '3%' }}>
                                                                    <span>{field.description}</span>
                                                                </div>

                                                                <div style={{ marginTop: '3%' }}>
                                                                    {field.adminImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.adminImageUpload}`} />}
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div style={{ marginLeft: '10%' }}>

                                                            <input disabled type='file' onChange={(value) => uploadFile(value, index1)} ></input>

                                                            <div style={{ marginTop: '3%' }}>
                                                                {field.userImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.userImageUpload}`} />}
                                                            </div>

                                                        </div>

                                                    </div>

                                                );
                                            } else {
                                                return (
                                                    <div>
                                                        <div className={styles.otherFields}>
                                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                                <div style={{ marginTop: '3%' }}>
                                                                    <span>{field.description}</span>
                                                                </div>

                                                                <div style={{ marginTop: '3%' }}>
                                                                    {field.adminImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.adminImageUpload}`} />}
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div style={{ marginLeft: '10%' }}>

                                                            <input type='file' onChange={(value) => uploadFile(value, index1)} ></input>

                                                            <div style={{ marginTop: '3%' }}>
                                                                {field.userImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.userImageUpload}`} />}
                                                            </div>

                                                        </div>

                                                    </div>
                                                );
                                            }
                                        })()}

                                        <div>
                                            <div style={{ display: 'inline' }}>
                                                {(() => {
                                                    const submission = userDashboard?.loadedTask?.section?.submissions;
                                                    //console.log("sending submission : ", submission);
                                                    const latestSubmission = getLatestSubmission(submission, index1);

                                                    //console.log("response area :", latestSubmission, index1);

                                                    if (latestSubmission?.listToReturn && latestSubmission.listToReturn[index1]) {
                                                        const submissionData = latestSubmission.listToReturn[index1];
                                                        return (
                                                            <div style={{ display: 'inline', textAlign: 'left' }}>
                                                                <span>Submitted: </span>
                                                                <span>{new Date(submissionData.timestamp).toLocaleString()} by </span>
                                                                <span>{submissionData.submittedBy?.username || 'Unknown User'}</span>
                                                            </div>
                                                        );
                                                    }

                                                    return null;
                                                })()}
                                            </div>
                                        </div>






                                    </div>
                                    )
                                }

                            })}

                            {loadingDashboard == false && userDashboard?.loadedTask?.section.type == 'Data Entry' && !(canViewPermission == true && userDashboard.loadedTask.section.submitted) && !(userDashboard?.loadedTask?.section?.submitted == true) &&
                                <div style={{ width: '25%' }}>

                                    <button onClick={addRow}> Add a Row</button>
                                </div>
                            }




                        </div>
                    </div>}


                    {(currentView == "trackedTask" && currentView != 'userSubmission' && currentView != 'summary') && < div style={{ display: 'flex' }}>
                        <div style={{ width: '1500px' }}>

                            <div className={styles.titleSection}>
                                <span> {userDashboard.loadedTask.task.form.name}</span>
                            </div>

                            {loadingDashboard == false && userDashboard?.loadedTask?.section?.fields?.map((field, index1) => {


                                if (userDashboard?.loadedTask?.section.type == 'Data Entry') {
                                    return (<div className={styles.DEfields} key={index1} >

                                        <div className={styles.DEfieldSection} style={{ width: '10%', minWidth: 'auto' }}>
                                            {index1 == 0 && <span>Item</span>}

                                            <div style={{ marginTop: '3%' }}>
                                                <span>{field.item.itemName}</span>

                                            </div>
                                        </div>

                                        {field.includePar == true &&

                                            <div className={styles.DEfieldSection} style={{ width: '10%', minWidth: 'auto' }}>
                                                <div className="checkboxes">
                                                    <label> {index1 == 0 && <span>Par</span>} </label>  </div>

                                                <span>{field.parValue}</span>

                                            </div>
                                        }


                                        {field?.userResponse?.map((obj, index2) => (

                                            <div className={styles.DEfieldSection} style={{ width: '240px', minWidth: 'auto' }}>

                                                <span> {obj.question}</span>

                                                <input className={styles.defaultInp} defaultValue={obj.answer} onChange={(evt) => settingUserResponseAnswer(evt, index1, index2)}></input>

                                            </div>

                                        ))}



                                        <button className={styles.popupBtn3} onClick={() => { addFieldToFormSection('Data Entry', forms?.formBeingMade?.sections.length - 1, forms.columnsToAdd) }} style={{ marginRight: '5%', width: '111px' }} >+ Add row</button>


                                    </div>
                                    )
                                }
                                if (userDashboard?.loadedTask?.section.type == 'Response') {
                                    return (<div className={styles.DEfields} key={index1} >

                                        {field?.responseColumns.map((col, index2) => {
                                            return (<div style={{ display: 'inline' }}>
                                                <span style={{ float: 'left', marginLeft: '2%' }}>{col.text}</span>

                                                <div style={{ marginTop: '2%' }}>
                                                    <input className={styles.defaultInp} defaultValue={col.userResponse} onChange={(evt) => updatingResponseField(evt.target.value, index1, index2)}></input>
                                                </div>
                                            </div>)

                                        })}





                                    </div>
                                    )
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Checklist') {
                                    return (<div className={styles.DEfields} key={index1} >
                                        <div className={styles.otherFields} style={{ minWidth: '100%', overflowX: 'auto' }}>
                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                <div style={{ marginTop: '3%', textAlign: 'left' }}>
                                                    <span>{field.description}</span>


                                                    <div style={{ marginTop: '3%' }}>
                                                        {field?.answerChoices.map((answer, index2) => {
                                                            return (<div style={{ display: 'inline' }}>
                                                                <button className={styles.orangeButton} style={{ backgroundColor: field.answerChoicesResponse == answer.text ? '#ffab3e' : '' }} onClick={() => updatingCheckboxField(answer.text, index1, index2)}>{answer.text}</button>
                                                            </div>)


                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    )
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Visual') {
                                    return (<div className={styles.DEfields} key={index1} >


                                        <div className={styles.otherFields} style={{ minWidth: '100%', overflowX: 'auto' }}>
                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>

                                                <div style={{ marginTop: '3%' }}>
                                                    <span>{field.description}</span>
                                                </div>

                                                <div style={{ marginTop: '3%' }}>
                                                    {field.adminImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.adminImageUpload}`} />}
                                                </div>

                                            </div>

                                        </div>


                                        <div style={{ marginLeft: '10%' }}>

                                            <input type='file' onChange={(value) => uploadFile(value, index1)} ></input>

                                            <div style={{ marginTop: '3%' }}>
                                                {field.userImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${field.userImageUpload}`} />}
                                            </div>

                                        </div>

                                    </div>
                                    )
                                }


                            })}

                        </div>

                    </div>}


                    {currentView == "userSubmission" && <div style={{ display: 'flex' }}>


                        <div id="popupBackground" onClick={() => { hidePopup() }} className={styles.popupBackground}>
                        </div>

                        <div id="popupMessage" className={styles.popupMessage} onClick={() => { hidePopup() }}>
                            <span>Choose the type of request you would like to generate</span>
                            <div>
                                <button onClick={() => { dispatch(setRequestTypeToGenerate("Purchase Request")) }} className={styles.popupBtn}>Purchase Request</button>

                            </div>

                            <div>
                                <button onClick={() => { dispatch(setRequestTypeToGenerate("Maintenance Request")) }} className={styles.popupBtn}>Maintenance Request</button>
                            </div>


                            <div>
                                <button onClick={() => { dispatch(setRequestTypeToGenerate("")) }} className={styles.popupBtn}>Cancel</button>
                            </div>


                        </div>

                        <div className={styles.userSub}>

                            <div className={styles.titleSection}>
                                <span> {userDashboard.loadedTask.task.form.name}</span>

                            </div>

                            {loadingDashboard == false && userDashboard?.loadedTask?.section?.fields?.map((sectionField, index1) => {
                                /* THIS IS THE USER SUBMISSION VIEW, WHEN A ADMIN IS VIEWING A COMPLETED FORM */
                                if (userDashboard?.loadedTask?.section.type == 'Data Entry') {
                                    return (<div className={styles.mobileContainer}>
                                        <div className={styles.DEfields} key={index1}>
                                            <div className={styles.otherFields3}>
                                                <div className={styles.DEfieldSection}>
                                                    {index1 == 0 && <span>Item</span>}
                                                    <div style={{ marginTop: '3%' }}>
                                                        <span>{sectionField.item.itemName}</span>
                                                    </div>
                                                </div>
                                                {sectionField.includePar == true && (
                                                    <div className={styles.DEfieldSection}>
                                                        <div className="checkboxes">
                                                            <label>{index1 == 0 && <span>Par</span>}</label>
                                                        </div>
                                                        <span>{sectionField.parValue}</span>
                                                    </div>
                                                )}
                                                <div className={styles.DEfieldSection}>
                                                    <div className="checkboxes">
                                                        <label>{index1 == 0 && <span>Current Quantity</span>}</label>
                                                    </div>
                                                    <input
                                                        id="quantityField"

                                                        defaultValue={sectionField.pendingCurrentQuantity}
                                                        className={styles.defaultInp}
                                                        onChange={(value) => updatingQuantityField(value, index1)}
                                                    ></input>
                                                </div>
                                                <div className={styles.DEfieldSection} style={{ textAlign: 'center' }}>
                                                    <div className="checkboxes">
                                                        <label>{index1 == 0 && <span>Recommendation</span>}</label>
                                                    </div>
                                                    <div style={{ marginTop: '5%', textAlign: 'center' }}>
                                                        <span>{sectionField.parValue}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.DEfieldSection}>
                                                    <div className="checkboxes">
                                                        <label>{index1 == 0 && <span>Add to Order</span>}</label>
                                                    </div>
                                                    <div className="checkboxes" style={{ marginTop: '5%' }}>
                                                        <label>
                                                            <input type="checkbox" onChange={(evt) => setAddToOrderCheckbox(evt, index1)} />
                                                            <input
                                                                className={styles.orangeButton}
                                                                style={{ width: '100px', height: '28px' }}
                                                                onChange={(evt) => settingOrderValue(evt, index1)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                {sectionField?.userResponse?.map((obj, index1) => (
                                                    <div className={styles.DEfieldSection} key={index1}>
                                                        <span>{obj.question}</span>
                                                        <span
                                                            disabled
                                                            className={styles.defaultInp}
                                                            defaultValue={obj.answer}
                                                            onChange={(evt) => settingUserResponseAnswer(evt, index1)}
                                                        ></span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                                if (userDashboard?.loadedTask?.section.type == 'Response') {
                                    return (<div className={styles.DEfields} key={index1} >

                                        <div className={styles.otherFields}>
                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                <div style={{ marginTop: '3%' }}>
                                                    <span>{sectionField.description}</span>

                                                    {sectionField?.responseColumns.map((col, index2) => {
                                                        return (<div style={{ display: 'inline' }}>
                                                            <span>{col.text}</span>

                                                            <div style={{ marginTop: '2%' }}>
                                                                <input className={styles.defaultInp} defaultValue={col.userResponse} onChange={(evt) => updatingResponseField(evt.target.value, index1, index2)}></input>
                                                            </div>
                                                        </div>)

                                                    })}

                                                </div>




                                            </div>

                                        </div>

                                    </div>
                                    )
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Checklist') {
                                    return (
                                        <div className={styles.DEfields} key={index1}>
                                            <div className={styles.otherFields}>
                                                <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                    <div style={{ marginTop: '3%' }}>
                                                        <span>{sectionField.description}</span>

                                                        <div>
                                                            <div style={{ display: 'inline' }}>
                                                                {userDashboard?.loadedTask?.section?.submissions &&
                                                                    userDashboard.loadedTask.section.submissions.length > 0 && (
                                                                        (() => {

                                                                            //console.log("Sending submissions again : ", userDashboard.loadedTask.section.submissions)
                                                                            const latestSubmission = getLatestSubmission(userDashboard.loadedTask.section.submissions, index1);

                                                                            if (latestSubmission) {
                                                                                return (
                                                                                    <div style={{ display: 'inline', textAlign: 'left' }}>
                                                                                        <span>Submitted: </span>
                                                                                        <span>{new Date(latestSubmission.timestamp).toLocaleString('en-US', {
                                                                                            hour: 'numeric',
                                                                                            minute: 'numeric'
                                                                                        })} by </span>
                                                                                        <span>{latestSubmission.submittedBy}</span>
                                                                                        {latestSubmission.newValue && <span> - {latestSubmission.newValue}</span>}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return null;
                                                                        })()
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }

                                if (userDashboard?.loadedTask?.section.type == 'Visual') {
                                    return (<div className={styles.DEfields} key={index1} >

                                        <div className={styles.otherFields}>
                                            <div className={styles.DEfieldSection} style={{ width: '600px' }}>
                                                <div style={{ marginTop: '3%' }}>
                                                    <span>{sectionField.description}</span>
                                                </div>

                                                <div style={{ marginTop: '3%' }}>
                                                    {sectionField.adminImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${sectionField.adminImageUpload}`} />}
                                                </div>

                                            </div>

                                        </div>


                                        <div style={{ marginLeft: '10%' }}>
                                            <input type='file' disabled onChange={(value) => uploadFile(value, index1)} ></input>


                                            <div style={{ marginTop: '3%' }}>
                                                {sectionField.userImageUpload && <img style={{ width: '200px', height: '150px' }} src={`data:image/jpeg;base64,${sectionField.userImageUpload}`} />}
                                            </div>

                                        </div>

                                    </div>
                                    )
                                }

                            })}

                        </div>


                    </div>}


                    {currentView == 'summary' &&
                        <div className={styles.pageWrapper2} >


                            <div className={styles.subPageHeaders}>
                                <span onClick={() => { openSubPage('task', userDashboard) }}>Form</span> <span style={{ marginLeft: '5%' }} onClick={() => { openSubPage('PendingPurchase', userDashboard) }}  >Order Summary</span>  <span style={{ marginLeft: '5%' }} onClick={() => { goToOrders() }}  >Orders</span>
                            </div>

                            <div className={styles.summaryArea}>

                                {userDashboard.summary && Object.keys(userDashboard.summary).map((companyName, index) => {
                                    if (userDashboard?.summary[companyName].hasBeenOrdered != true) {
                                        return (
                                            <div className={styles.summaryItem}>

                                                <div className={styles.cardHeader}>
                                                    <span style={{ marginBottom: '2%' }}>
                                                        {companyName} | Contact
                                                    </span>
                                                </div>

                                                <div className={styles.topCard}>
                                                    <div style={{ paddingLeft: '1%', paddingTop: '1%', textAlign: 'left' }}>

                                                        <span>Contact Information</span>

                                                    </div>

                                                    <div className={styles.midCard}>

                                                        <div className={styles.cardRow}>
                                                            <div className={styles.cardLineItem}>
                                                                <span className={styles.leftItem1}> Phone: </span>

                                                                <span className={styles.rightItem1}>{getVendorInfo(userDashboard.summary, companyName)?.phoneNumber} </span>

                                                            </div>

                                                            <div className={styles.cardLineItem}>


                                                                <span className={styles.leftItem1} >
                                                                    Min Order:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.minOrder}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className={styles.cardRow}>
                                                            <div className={styles.cardLineItem}>
                                                                <span className={styles.leftItem1}>
                                                                    Email:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.contactEmail}
                                                                </span>
                                                            </div>


                                                            <div className={styles.cardLineItem}>

                                                                <span className={styles.rightItem1}>
                                                                    Order Deadline:
                                                                </span>
                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.orderDeadline}
                                                                </span>
                                                            </div>


                                                        </div>

                                                        <div className={styles.cardRow}>

                                                            <div className={styles.cardLineItem}>

                                                                <span className={styles.leftItem1}>
                                                                    Contact Person:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.contactPerson}
                                                                </span>


                                                            </div>


                                                            <div className={styles.cardLineItem}>

                                                                <span className={styles.leftItem1}>
                                                                    Instructions:
                                                                </span>
                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.instructions}
                                                                </span>
                                                            </div>


                                                        </div>



                                                        <div className={styles.cardRow}>


                                                            <div className={styles.cardLineItem}>
                                                                <span className={styles.leftItem1}>
                                                                    Website:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.website}
                                                                </span>


                                                            </div>

                                                        </div>

                                                        <div className={styles.cardRow}>

                                                            <div className={styles.cardLineItem}>
                                                                <span>
                                                                    Username:
                                                                </span>


                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.username}
                                                                </span>

                                                            </div>



                                                            <div className={styles.cardLineItem}>


                                                                <span className={styles.leftItem1}>
                                                                    Payment Terms:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.paymentTerms}
                                                                </span>
                                                            </div>

                                                        </div>

                                                        <div className={styles.cardRow}>
                                                            <div className={styles.cardLineItem}>


                                                                <span className={styles.leftItem1}>
                                                                    Password:
                                                                </span>

                                                                <span className={styles.rightItem1}>
                                                                    {getVendorInfo(userDashboard.summary, companyName)?.password}
                                                                </span>


                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>

                                                <div className={styles.blackLineDiv2}>
                                                    &nbsp;
                                                </div>

                                                <div className={styles.bottomCard}>
                                                    <div className={styles.leftHalfBC}>
                                                        <div>

                                                            <span>Order Summary</span> <span style={{ marginLeft: '5%' }}>Purchase No:  </span>   <span style={{ marginLeft: '15%' }}>Note</span> <span  >{getVendorInfo(userDashboard.summary, companyName)?.Note}</span>
                                                        </div>

                                                        {getVendorItems(userDashboard.summary, companyName)?.map((item, itemIndex) => {
                                                            return (
                                                                <div>
                                                                    <span style={{ width: '100px' }}>{item.itemName}  </span> <span> Qty:{item.pendingOrderQuantity}</span>

                                                                </div>
                                                            )

                                                        })}

                                                        <span>Arrive on (?)</span>

                                                    </div>

                                                    <div className={styles.rightHalfTC}>
                                                        <div>
                                                            <input type="checkbox" /> <span>Website</span>
                                                        </div>

                                                        <div>
                                                            <input type="checkbox" /> <span>Phone</span>
                                                        </div>

                                                        <div>
                                                            <input type="checkbox" /> <span>Email</span>
                                                        </div>

                                                    </div>

                                                    <div style={{ paddingTop: '2%', paddingRight: '2%' }}>
                                                        <button className={styles.donebtn} onClick={() => { createOrderAndShowOrders('PendingPurchase', userDashboard, userDashboard.summary[companyName]) }}>Done</button>

                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    }

                                })}

                            </div>

                        </div>
                    }

                </div>

                {(currentView == "task" || currentView == "reviewingTask" || currentView == "trackedTask") &&
                    <div>

                        {canViewPermission == true && userDashboard?.loadedTask?.section?.submitted == true &&
                            <div className={styles.submitCancelWrapper} style={{ display: 'flex' }}>
                                <button style={{ backgroundColor: 'black', color: 'white' }} onClick={() => { onCancelClicked() }} className={styles.formBtn}>Cancel</button>
                                <button
                                    style={{
                                        backgroundColor: userDashboard?.loadedTask?.section?.fields?.some(field => field.triggerPurchase === true)
                                            ? '#fc8f14'
                                            : 'grey',

                                        color: 'white'
                                    }}
                                    onClick={onSubmitClicked}
                                    className={styles.formBtn}
                                >
                                    {userDashboard?.loadedTask?.section?.fields?.some(field => field.triggerPurchase === true)
                                        ? 'Submit (Order Checkout)'
                                        : 'Already Submitted'
                                    }                        </button>
                            </div>}

                        {(() => {/*
                            const part1 = canViewPermission === false;
                            const part2 = canViewPermission === true;
                            const part3 = !userDashboard?.loadedTask?.section?.hasOwnProperty('submitted');
                            const part4 = userDashboard?.loadedTask?.section?.submitted === false;
                            const secondCondition = part2 && (part3 || part4);
                            const finalCondition = part1 || secondCondition;

                            //console.log({
                                "1. canViewPermission is false": part1,
                                "2. canViewPermission is true": part2,
                                "3. section doesn't have submitted property": part3,
                                "4. section.submitted is false": part4,
                                "5. Combined (true && (no submitted || submitted false))": secondCondition,
                                "6. Final result (part1 || secondCondition)": finalCondition,
                                "Current canViewPermission value": canViewPermission,
                                "Current section object": userDashboard?.loadedTask?.section,
                                "Current submitted value": userDashboard?.loadedTask?.section?.submitted
                            });

                            return null;*/
                        })()}
                        {(canViewPermission === false || (!userDashboard?.loadedTask?.section?.hasOwnProperty('submitted') || userDashboard?.loadedTask?.section?.submitted === false)) &&
                            <div className={styles.submitCancelWrapper} style={{ display: 'flex' }}>
                                <button style={{ backgroundColor: 'black', color: 'white' }} onClick={() => { onCancelClicked() }} className={styles.formBtn}>Cancel</button>


                                <button
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#fc8f14',
                                        color: 'white'
                                    }}
                                    onClick={() => saveFormProgress(userDashboard)}
                                    className={styles.formBtn}
                                >
                                    Save
                                </button>


                                <button
                                    style={{
                                        cursor: userDashboard?.loadedTask?.section?.submitted == true ? 'not-allowed' : 'pointer',
                                        backgroundColor: userDashboard?.loadedTask?.section?.submitted == true ? 'grey' : '#fc8f14',
                                        color: 'white'
                                    }}
                                    onClick={onSubmitClicked}
                                    disabled={userDashboard?.loadedTask?.section?.submitted == true ?? false}
                                    className={styles.formBtn}
                                >
                                    Submit
                                </button>
                            </div>}



                    </div>

                }

                {
                    currentView == "userSubmission"
                    && <div className={styles.submitCancelWrapper}>

                        <div className={styles.bottomSection}>
                            <div className={styles.formBtnDiv}>
                                <button onClick={() => { showPopup() }} className={styles.createAnotherRequestBtn}>{userDashboard?.loadedTask.requestTypeToGenerate ? 'Will create a ' + userDashboard?.loadedTask.requestTypeToGenerate + ' when submitted' : 'Generate request from task'}</button>

                                <button style={{ backgroundColor: 'black', color: 'white' }} onClick={() => { window.location.reload() }} className={styles.formBtn}>Back</button>


                                <button
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: '#fc8f14',
                                        color: 'white'
                                    }}
                                    onClick={() => saveFormProgress(userDashboard)}
                                    className={styles.formBtn}
                                >
                                    Save
                                </button>


                                <button style={{ backgroundColor: '#fc8f14', color: 'white' }} onClick={() => { onSubmitClicked() }} className={styles.formBtn}>Submit (user submission)</button>
                            </div>
                        </div>
                    </div>
                }


            </div >


        </>
    )

    function handleRemove(index) {
        dispatch(removeFieldFromForm({ fieldIndex: index }));
    };

    function itemChangedInLateField(selectedItem, fieldIndex) {
        dispatch(changeItemInField({
            item: selectedItem,
            fieldIndex: fieldIndex
        }));
    };


    function addRow() {
        console.log("add row clicked")
        //  ('Data Entry') type type
        // Find section index using section._id instead of sectionName for more reliable matching
        const findSectionIndex = (sections, targetSection) => {
            if (!sections || !Array.isArray(sections)) {
                return -1;
            }
            return sections.findIndex(section =>
                section.sectionName === targetSection.sectionName &&
                section.type === targetSection.type
            );
        };

        const sectionIndex = findSectionIndex(
            userDashboard.loadedTask.task.form.sections,
            userDashboard.loadedTask.section
        );

        // Guard against invalid section index
        if (sectionIndex === -1) {
            console.warn("Section not found", {
                sections: userDashboard.loadedTask.task.form.sections,
                targetSection: userDashboard.loadedTask.section
            });
            return;
        }


        console.log("Checking section Index :", sectionIndex)


        dispatch(addRowToForm(sectionIndex))

    }

    async function backupSubmission(data) {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/backup-form/${data.form._id}`,
                { method: 'POST' }
            );
            const result = await response.json();

            setBackupStatus({
                show: true,
                status: result.message === 'Backup successful' ? 'success' : 'error',
                message: result.message || 'Unknown error occurred'
            });

            if (result.message = "Backup successful") {
                // Refresh all necessary data
                setTimeout(() => {
                    dispatch(setPageView("Dashboard"));
                    dispatch(fetchUserSubmittedTask({
                        org_id: user.org_id,
                        viewingLocation: user?.viewingLocation,
                        user_id: user._id,
                        job_id: user.jobPosition._id
                    }));
                    dispatch(initializeJobsAndLoadTasks({
                        org_id: user.org_id,
                        viewingLocation: user?.viewingLocation,
                        user_id: user._id,
                        job_id: user.jobPosition
                    }));
                    dispatch(getFormSectionsAssignedToActiveUser({
                        org_id: user.org_id,
                        viewingLocation: user?.viewingLocation,
                        user_id: user._id,
                        job_id: user.jobPosition
                    }));
                    dispatch(getFormSectionsReportingToActiveUser({
                        org_id: user.org_id,
                        viewingLocation: user?.viewingLocation,
                        user_id: user._id,
                        job_id: user.jobPosition
                    }));
                    dispatch(getTrackedTasksReq({
                        org_id: user.org_id,
                        viewingLocation: user?.viewingLocation,
                        user_id: user._id,
                        job_id: user.jobPosition
                    }));
                }, 1000);

            }
        } catch (error) {
            setBackupStatus({
                show: true,
                status: 'error',
                message: error.message
            });
        }
    }

    async function ResetSubmission(data) {
        console.log("backupSubmissionAndReset clicked:", data);
        try {
            // Make the POST request to reset the form
            /*        const response = await fetch(
                        `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/reset-form/${data.form._id}`,
                        {
                            method: 'POST'
                        }
                    );
        
                    const result = await response.json();
                    console.log('Reset result:', result);*/
            let resetForm = structuredClone(data.form);

            /*
                        if (result.message === 'Backup successful') {
                            // Create a deep copy of the form data
                        } else {
                            console.error('Form reset failed:', result.error);
                        }
            */

            // Clear answers in the form copy
            resetForm.form.sections.forEach(section => {
                console.log("check section.name == data.section.name && section.fields.length == data.fields.length :", section.sectionName, data.section.sectionName, section.fields, data.section.fields)
                if (section.sectionName == data.section.sectionName && section.fields.length == data.section.fields.length) {

                    section.submissions = [];
                    section.submitted = false;
                    section.cart = {};

                    if (section.fields) {
                        section.fields.forEach(field => {
                            if (section.type === "Data Entry") {
                                // Reset all answers in userResponse array
                                if (field.userResponse && Array.isArray(field.userResponse)) {
                                    field.userResponse.forEach(response => {
                                        response.answer = "0";  // Reset to "0" since these are typically numeric inputs
                                    });
                                }
                            }
                            else if (section.type === "Visual") {
                                field.userImageUpload = "";
                            }
                            else if (section.type === "Response") {
                                if (field.userResponse && Array.isArray(field.userResponse)) {
                                    field.userResponse = [];
                                } else {
                                    field.userResponse = "";
                                }
                                // Clear response columns if they exist
                                if (field.responseColumns) {
                                    field.responseColumns.forEach(col => {
                                        col.userResponse = "";
                                    });
                                }
                            }
                            else if (section.type === "Checklist") {
                                field.answerChoicesResponse = "";
                                field.userResponse = "";
                            }
                        });
                    }
                }

            });


            console.log("form being reset :", resetForm)
            // Update the form in database with cleared answers
            dispatch(patchForm(resetForm));

            // Refresh all necessary data
            setTimeout(() => {
                dispatch(setPageView("Dashboard"));
                dispatch(fetchUserSubmittedTask({
                    org_id: user.org_id,
                    viewingLocation: user?.viewingLocation,
                    user_id: user._id,
                    job_id: user.jobPosition._id
                }));
                dispatch(initializeJobsAndLoadTasks({
                    org_id: user.org_id,
                    viewingLocation: user?.viewingLocation,
                    user_id: user._id,
                    job_id: user.jobPosition
                }));
                dispatch(getFormSectionsAssignedToActiveUser({
                    org_id: user.org_id,
                    viewingLocation: user?.viewingLocation,
                    user_id: user._id,
                    job_id: user.jobPosition
                }));
                dispatch(getFormSectionsReportingToActiveUser({
                    org_id: user.org_id,
                    viewingLocation: user?.viewingLocation,
                    user_id: user._id,
                    job_id: user.jobPosition
                }));
                dispatch(getTrackedTasksReq({
                    org_id: user.org_id,
                    viewingLocation: user?.viewingLocation,
                    user_id: user._id,
                    job_id: user.jobPosition
                }));
            }, 1000);

        } catch (error) {
            console.error('Reset failed:', error);
        }
    }

    async function download(data) {
        console.log("Download clicked:", data);
        try {
            // Create a POST request with the data in the body
            const response = await fetch(
                `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/download-aggregation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        formId: data.form._id,
                        section: data.section,
                        format: data.section.type === "Visual" ? "pdf" : "csv"  // Set format to pdf for Visual sections
                    })
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // Get the filename from the content-disposition header if present
            const contentDisposition = response.headers.get('content-disposition');
            let filename = '';

            if (contentDisposition && contentDisposition.includes('filename=')) {
                // Extract filename from content-disposition header
                filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
            } else {
                // Fallback filename generation
                const extension = data.section.type === "Visual" ? "pdf" : "csv";  // Changed extension to pdf
                const sectionName = data.section.name || data.section.sectionName
                if (sectionName) {
                    filename = `${data.form.name}_${sectionName}_${new Date().toLocaleDateString()}.${extension}`;
                } else {
                    filename = `${data.form.name}_${new Date().toLocaleDateString()}.${extension}`;

                }
            }

            a.download = filename;
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download failed:', error);
            alert('Failed to download file. Please try again.');
        }
    }


    async function clearAnswerField(data) {
        console.log("Download clicked :", data)
        try {
            const response = await fetch(`${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_PORT}/clear-answers/${data.form._id}`);
            /* const blob = await response.blob();
             const url = window.URL.createObjectURL(blob);
             const a = document.createElement('a');
             a.href = url;
             a.download = 'data.csv';
             a.click();*/
        } catch (error) {
            console.error('Reset failed:', error);
        }
    }

    function setClickedJobPosition(job) {

        //console.log("check? :", userDashboard?.selectedJobPosition, job, !userDashboard?.selectedJobPosition?._id, (job == "Your assigned Tasks", job != userDashboard?.selectedJobPosition))

        if (!userDashboard?.selectedJobPosition?._id) {
            if ((job == "Your assigned Tasks" && userDashboard?.selectedJobPosition == "Your assigned Tasks") || (job == "User form submissions" && userDashboard?.selectedJobPosition == "User form submissions")) {
                dispatch(setSelectedJob({}))

            }
            else {
                dispatch(setSelectedJob(job))

            }

        }
        else {

            //console.log("else check :", job, UserDashboard)


            if (job._id == userDashboard?.selectedJobPosition?._id || (job == "Your assigned Tasks" && job == userDashboard?.selectedJobPosition) || (job == "User form submissions" && job == userDashboard?.selectedJobPosition)) {
                dispatch(setSelectedJob({}))
            }
            else {
                dispatch(setSelectedJob(job))
            }
        }

    }

    function goToOrders(summaryItem) {
        // Store task data in localStorage
        localStorage.setItem('pendingPurchaseTask', JSON.stringify(userDashboard.loadedTask));

        localStorage.setItem('summaryItem', JSON.stringify(summaryItem));



        // Navigate with fromForm parameter
        window.location.href = `${process.env.REACT_APP_EUSOCIAL_API_URL}${process.env.REACT_APP_EUSOCIAL_FRONTEND_PORT}/Purchase_Request?view=PendingPurchase&fromForm=true`;
    }


    function resetPage() {
        dispatch(setTask({}))
        dispatch(setPageView('Task'))

    }
    function CustomSelect({
        placeholder,
        id,
        options,
        className,
        value,
        onChange,
        multiple,
        isClearable,
        getOptionLabel = (option) => option?.itemName,
        getOptionValue = (option) => option?.itemName,
        ...props
    }) {
        const [isOpen, setIsOpen] = useState(false);

        const handleChange = (e) => {
            if (e.target.value === 'reset') {
                onChange(null);
            } else {
                const selectedOption = options.find(
                    (option) => getOptionValue(option) === e.target.value
                );
                onChange(selectedOption);
            }
        };

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        // Helper function to get the current value for the select
        const getCurrentValue = () => {
            return value ? getOptionValue(value) : '';
        };

        return (
            <div className={`custom-select ${isOpen ? 'open' : ''}`}>
                <select
                    id={id}
                    className={className}
                    value={getCurrentValue()}
                    onChange={handleChange}
                    onBlur={toggleDropdown}
                    onFocus={toggleDropdown}
                    {...props}
                >
                    {placeholder && <option value="" disabled>{placeholder}</option>}
                    {options?.map((option) => (
                        <option
                            key={String(getOptionValue(option))}
                            value={String(getOptionValue(option))}
                        >
                            {getOptionLabel(option)}
                        </option>
                    ))}
                    {isClearable && <option value="reset">Reset</option>}
                </select>
            </div>
        );
    }
    function openSubPage(view, state) {

        if (view == 'summary') {
            let summary = generateSummaryFromLoadedTask(userDashboard.loadedTask)

            dispatch(setDashboardSummary(summary))

        }

        //  console.log("Setting sub page :", view)
        // console.log("Current selected form/task/order  ", userDashboard.loadedTask)


        //    console.log("Function test:  ", generateSummaryFromLoadedTask(userDashboard.loadedTask))

        dispatch(setPageView(view))
    }

    function showPopup1() {
        document.getElementById("popupMessage").style.visibility = "visible"
        document.getElementById("popupBackground").style.visibility = "visible"
    }

    function hidePopup1() {
        document.getElementById("popupMessage").style.visibility = "hidden"
        document.getElementById("popupBackground").style.visibility = "hidden"
    }


    function saveFormProgress(state) {
        console.log("Save form clicked ");


        // Create a copy of the loadedTask object
        let originalFormToMarkOff = structuredClone(state.loadedTask);

        // Extract the section data from the loadedTask object
        const sectionData = originalFormToMarkOff.section;


        //Any fields added late need to have that property marked to false, we're making them permanent fields here 

        originalFormToMarkOff.task.form.sections.forEach(section => {
            section.fields.forEach(element => {
                if (element) {
                    if (element.addedLate) {
                        element.addedLate = false;
                    }
                }
            });
        });


        let originalFormToMarkOff2 = structuredClone(originalFormToMarkOff.task);

        console.log("checking originalFormToMarkOff2:", originalFormToMarkOff2)
        // Update the form in database
        dispatch(patchForm(originalFormToMarkOff2));

        // dispatch(patchFormSection({ form: originalFormToMarkOff, section: sectionData, _id: originalFormToMarkOff.task._id, checkout: {} }));

        setTimeout(() => {
            dispatch(setPageView("Dashboard"))
            dispatch(fetchUserSubmittedTask({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition._id }))
            dispatch(initializeJobsAndLoadTasks({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getFormSectionsAssignedToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getFormSectionsReportingToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getTrackedTasksReq({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))

        }, 700);
    }


    function calculateDueDate(section,) {

        //console.log("Get due date from this : ", section)

        //Given a list of expected times to report, how do we know which one we're at
        //Answer: the schedules are date sorted, earliest date first.
        // -- the sweeper on backend will mark things as missed and toggle them from showing up


        //again we are assuming that the first item in schedule list is always next upcoming one?
        //we should not allow past dates to be selected on the calendar


        /*
                let finalDueDate = new Date()
                let toShow
                if (section.schedule[0].due == "One Time") {
                    finalDueDate = new Date(section.schedule[0].due)
                }
        
                else if (section.schedule[0].due == "3 Days") {
                    finalDueDate = new Date(section.schedule[0].due).getDate() + 3;
                }
        
                else if (section.schedule[0].due == "5 Days") {
                    finalDueDate = new Date(section.schedule[0].due).getDate() + 5;
                }
        
                else if (section.schedule[0].due == "7 Days") {
                    finalDueDate = new Date(section.schedule[0].due).getDate() + 7;
                }
        
                //console.log("Due date to show : ", finalDueDate)
        
        
        
        
                let data;
        
        
                return (
                    < div >
                        {finalDueDate.toLocaleDateString()}
                    </div >
        
                )*/

    }

    function setAddToOrderCheckbox(evt, index1, index2, index3) {
        //console.log("setAddToOrderCheckbox evt, index1, index2, index3:", evt.target.checke, index1, index2, index3)

        dispatch(setAdminReviewCheckbox({ value: evt.target.checke, index1, index2, index3 }))
    }



    /*function createOrderAndShowOrders(view, state, create = 'no') {
    
    
        //create is a vendor block getting clicked 'done' 
    
        //view is the state
    
        //This function needs to remove the 'create' parameter vendor block
        //from the state.loadedtask.summary and create a pending purchase from that vendor block
        //the form/section should be patched with summary updated
    
        let checkOutCopy = structuredClone(create)
    
        let originalFormToMarkOff = structuredClone(state.loadedTask)
    
        //   //console.log("Checked out : ", checkOutCopy)
        //CREATE IS THE SHOPPING CART ITEMS LIST
        //STATE INCLUDES THE form THATS BEING CHCKED OUT
    
    
        // Mark items as ordered and remove from checkout copy
        create.forEach(element => {
            const index = checkOutCopy.findIndex(item => item._id === element._id);
            const index2 = originalFormToMarkOff.section.fields.findIndex(item => item._id === element._id);
    
            if (index2 !== -1) {
                originalFormToMarkOff.section.fields[index2].hasBeenOrdered = true;
            }
            if (index !== -1) {
                checkOutCopy.splice(index, 1);
            }
        });
    
        // Group remaining items by vendor
        const groupedCheckout2 = state.summary.reduce((groups, item) => {
            if (!item.vendor?.company) return groups;
    
            const vendorName = item.vendor.company;
            if (!groups[vendorName]) {
                groups[vendorName] = [];
            }
            groups[vendorName].push(item);
            return groups;
        }, {});
        // //console.log("Checked out after: ", (groupedCheckout2))
    
    
        dispatch(setDashboardSummary(groupedCheckout2))
    
        originalFormToMarkOff.section.checkedOut = checkOutCopy
        originalFormToMarkOff.section.summary = groupedCheckout2
        originalFormToMarkOff.section.cart = checkOutCopy
    
        originalFormToMarkOff.section.orderPlaced = true
    
        dispatch(patchForm(originalFormToMarkOff)) //verify this works 11/9 12:06
    
        let formBeingUpdated = originalFormToMarkOff.task
    
    
        dispatch(patchSubmissionOfSection({
            form: formBeingUpdated,
            section: originalFormToMarkOff.section,
            _id: formBeingUpdated._id,
            checkout: checkOutCopy
        }));
    
    
        dispatch(createPendingPurchase({ create, "org_id": user.org_id, location: user.viewingLocation }))
    
    
        console.log("grouped checkout 2 :", groupedCheckout2)
        console.log("create:", create)
        console.log("checked out copy :" , checkOutCopy)
    
       
                setTimeout(() => {
                    goToOrders(groupedCheckout2)
                }, 1400);
    }*/




    function createOrderAndShowOrders(view, state, create = 'no') {
        let originalFormToMarkOff = structuredClone(state.loadedTask);

        const updatedSummary = { ...state.summary };

        if (create != "no") {
            // Mark items in original form as ordered
            create.forEach(element => {
                const index2 = originalFormToMarkOff.section.fields.findIndex(item => item._id === element._id);
                if (index2 !== -1) {
                    originalFormToMarkOff.section.fields[index2].hasBeenOrdered = true;
                }
            });

            // Mark vendor block as checked out instead of removing it
            const vendorName = create[0]?.vendor?.company;
            if (updatedSummary[vendorName]) {
                updatedSummary[vendorName] = {
                    items: updatedSummary[vendorName],
                    hasBeenOrderedDate: new Date().toISOString(),
                    hasBeenOrdered: true // Or whatever user identifier you have
                };
            }

        }
        else {
            // Remove the vendor block that's being checked out from summary
            // const vendorToRemove = create[0]?.vendor?.company;
            //   delete updatedSummary[vendorToRemove];

        }

        // Update state and form
        dispatch(setDashboardSummary(updatedSummary));
        originalFormToMarkOff.section.summary = updatedSummary;
        originalFormToMarkOff.section.orderPlaced = true;

        // Update the form in database
        dispatch(patchForm(originalFormToMarkOff));

        // Update submission
        let formBeingUpdated = originalFormToMarkOff.task;
        dispatch(patchSubmissionOfSection({
            form: formBeingUpdated,
            section: originalFormToMarkOff.section,
            _id: formBeingUpdated._id,
            checkout: create // Send the vendor block being checked out
        }));

        if (create != 'no') {
            console.log("createPendingPurchase create ", create)
            // Create pending purchase for the vendor block
            dispatch(createPendingPurchase({
                create,
                "org_id": user.org_id,
                location: user.viewingLocation
            }));

        }
        /*
                setTimeout(() => {
                    goToOrders(updatedSummary)
                }, 1400);
        */
    }



    function settingOrderValue(evt, index1, index2, index3) {
        //console.log("setOrderValue evt, index1, index2, index3:", evt, index1, index2, index3)

        dispatch(setOrderValue({ value: evt.target.value, index1, index2, index3 }))

    }
    function hidePopup() {
        document.getElementById("popupMessage").style.visibility = "hidden"
        document.getElementById("popupBackground").style.visibility = "hidden"
    }

    function showPopup() {
        document.getElementById("popupBackground").style.visibility = "visible"
        document.getElementById("popupMessage").style.visibility = 'visible'
    }

    async function uploadFile(value, index1, index2, index3) {
        //File name is value.target.value
        //console.log("uploading file :", value.target.value, value.target.files)
        //console.log("File url? :", value.target.files[0])

        // Find section index using section._id instead of sectionName for more reliable matching
        const findSectionIndex = (sections, targetSection) => {
            if (!sections || !Array.isArray(sections)) {
                return -1;
            }
            return sections.findIndex(section =>
                section.sectionName === targetSection.sectionName &&
                section.type === targetSection.type
            );
        };

        const sectionIndex = findSectionIndex(
            userDashboard.loadedTask.task.form.sections,
            userDashboard.loadedTask.section
        );

        // Guard against invalid section index
        if (sectionIndex === -1) {
            console.warn("Section not found", {
                sections: userDashboard.loadedTask.task.form.sections,
                targetSection: userDashboard.loadedTask.section
            });
            return;
        }



        await dispatch(uploadingFile({ name: value.target.value, files: value.target.files[0] }))

        await dispatch(getOneFile({ value: value.target.files[0].name, index1, index2, index3, sectionIndex }))

        /*
        var reader = new FileReader();
        reader.readAsDataURL(value.target.files[0])
        reader.onload = () => {
                //reader.result url value to display image
                dispatch(uploadingFile({ name: value.target.value, files: reader.result }))
     
            }
        reader.onerror = () => {
                //console.log("Error :", error)
            }*/

    }

    //FOR DATA ENTRY FIELD NOT THE 'RESPONSE' FIELD
    function settingUserResponseAnswer(item, fieldIndex, columnIndex) {
        //console.log("Setting user Answer : ", item.target.value)


        // Find section index using section._id instead of sectionName for more reliable matching
        const findSectionIndex = (sections, targetSection) => {
            if (!sections || !Array.isArray(sections)) {
                return -1;
            }
            return sections.findIndex(section =>
                section.sectionName === targetSection.sectionName &&
                section.type === targetSection.type
            );
        };

        const sectionIndex = findSectionIndex(
            userDashboard.loadedTask.task.form.sections,
            userDashboard.loadedTask.section
        );

        // Guard against invalid section index
        if (sectionIndex === -1) {
            console.warn("Section not found", {
                sections: userDashboard.loadedTask.task.form.sections,
                targetSection: userDashboard.loadedTask.section
            });
            return;
        }


        dispatch(setUserResponseAnswerForDataEntry({ prompt: item.target.value, fieldIndex: fieldIndex, columnIndex: columnIndex, sectionIndex: sectionIndex }))

    }

    function testScheduler() {
        dispatch(testScheduler2())
    }

    function getDaysOfWeekFromData(display) {
        //console.log("recieving :  ", display)

        if (!display) {
            display = []
        }
        const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const letterMap = {
            "Sunday": "Su",
            "Monday": "M",
            "Tuesday": "T",
            "Wednesday": "W",
            "Thursday": "Th",
            "Friday": "F",
            "Saturday": "Sa"
        };

        // Create a map of days present in the display data
        const presentDays = new Map(display.map(item => [item.label, item]));

        // Sort and map the days to their abbreviated forms
        const letterList = dayOrder
            .filter(day => presentDays.has(day))
            .map(day => letterMap[day]);

        return letterList;
    }


    function getLatestSubmission(submissions, fieldIndex) {   //submissions here is either a list of subs or a single one
        let listToReturn = {}

        if (!submissions) {
            return {}
        }


        ////console.log("looking at submissions : ", submissions, fieldIndex)


        //console.log("collecting submissions", submissions)

        submissions.forEach(submit => {

            submit.submittedFields.forEach(element => {
                //  //console.log("Checking : " , element.answerChoicesResponse ?? element.responseColumns.userResponse ?? element.userImageUpload)

                listToReturn[element.fieldIndex] = {
                    submittedBy: element.submittedBy ? element.submittedBy : submit.submittedBy,
                    timestamp: element.submissionDate,
                    //
                    value: element?.answerChoicesResponse ?? element?.responseColumns ?? element?.userImageUpload ?? element?.userResponse,
                    fieldIndex: element.fieldIndex
                }
            });

            //console.log("submit. inside loop. submissions : ", submit, listToReturn)

        });

        //console.log("multiple submissions found, returnedList : ", submissions, listToReturn)


        return {
            listToReturn
        }

    };


    function generateSummaryFromLoadedTask(loadedTask) {
        //   console.log("function recieved :" , loadedTask)
        if (loadedTask?.section) {
            const dataEntrySection = loadedTask.section

            //   console.log("Check function: " ,dataEntrySection && dataEntrySection.cart )
            if (dataEntrySection && dataEntrySection.cart) {
                const summary = dataEntrySection.cart.reduce((acc, item) => {
                    if (acc[item.vendor.company]) {
                        acc[item.vendor.company].push(item);
                    } else {
                        acc[item.vendor.company] = [item];
                    }
                    return acc;
                }, {});

                return summary;
            }
        }

        return null;
    }



    function onSubmitClicked() {
        console.log('onSubmitClicked : ', userDashboard.loadedTask);

        const canViewPermission = userDashboard?.loadedTask?.section?.submitted;
        const isAdmin = canViewPermission === true;


        console.log("Save form clicked ");


        // Create a copy of the loadedTask object
        let originalFormToMarkOff = structuredClone(userDashboard.loadedTask);

        //Any fields added late need to have that property marked to false, we're making them permanent fields here 

        originalFormToMarkOff.task.form.sections.forEach(section => {
            section.fields.forEach(element => {
                if (element) {
                    if (element?.addedLate) {
                        element.addedLate = false;
                    }
                }
            });
        });


        // Extract the section data from the loadedTask object
        const sectionData = originalFormToMarkOff.section;

        if (isAdmin) {
            console.log("first section SUBMISSION : ADMIN REVIEWING USER'S POST");
            const sectionType = userDashboard?.loadedTask?.section?.type;

            if (sectionType === "Data Entry") {
                // ... existing Data Entry handling code ...
            } else {
                console.log("Else area reached section");

                const originalTaskToMarkOff = structuredClone(userDashboard.loadedTask);
                const formBeingUpdated = originalTaskToMarkOff.task;
                formBeingUpdated.adminChecked = true;

                // Ensure section is updated in both places
                const sectionName = originalTaskToMarkOff.section.sectionName;
                const sectionType = originalTaskToMarkOff.section.type;

                // Update the section in the form's sections array
                if (formBeingUpdated.form?.sections) {
                    const sectionIndex = formBeingUpdated.form.sections.findIndex(s =>
                        s.sectionName === sectionName && s.type === sectionType
                    );
                    if (sectionIndex !== -1) {
                        formBeingUpdated.form.sections[sectionIndex] = {
                            ...formBeingUpdated.form.sections[sectionIndex],
                            ...originalTaskToMarkOff.section
                        };
                    }
                }

                dispatch(patchSubmissionOfSection({
                    form: formBeingUpdated,
                    section: originalTaskToMarkOff.section,
                    _id: formBeingUpdated._id
                }));
            }
        } else {
            console.log("second section SUBMISSION : User Posting");

            const cloneOfSubmission = structuredClone(userDashboard.loadedTask);
            let sectionToMarkAsSubmitted = cloneOfSubmission.section;
            let formBeingUpdated = cloneOfSubmission.task;

            const newSubmission = {
                type: 'trackedTask',
                submittedBy: { user },
                submissionDate: new Date(),
                submittedFields: []
            };

            let submittedFields = 0;
            const totalFields = sectionToMarkAsSubmitted.fields.length;

            for (let i = 0; i < totalFields; i++) {
                const field = sectionToMarkAsSubmitted.fields[i];

                if (field.userImageUpload || field.userResponse?.length > 0 ||
                    field.answerChoicesResponse || field.responseColumns?.some(col => col.userResponse)) {

                    const submittedIndices = sectionToMarkAsSubmitted.submissions?.flatMap(sub =>
                        sub.submittedFields.map(f => f.fieldIndex)) || [];

                    if (!submittedIndices.includes(i)) {
                        const fieldSubmission = {
                            ...structuredClone(field),
                            submittedBy: user,
                            fieldIndex: i,
                            submissionDate: new Date()
                        };

                        newSubmission?.submittedFields?.push(fieldSubmission);
                        submittedFields++;
                    }
                }
            }

            console.log("section check : ", sectionToMarkAsSubmitted);
            const submissionStatus = submittedFields >= totalFields ? true : "Partial";
            sectionToMarkAsSubmitted.submitted = submissionStatus;

            if (!sectionToMarkAsSubmitted.submissions) {
                sectionToMarkAsSubmitted.submissions = [];
            }
            sectionToMarkAsSubmitted.submissions.push(newSubmission);

            // Update the section in the form's sections array
            if (formBeingUpdated.form?.sections) {
                const sectionIndex = formBeingUpdated.form.sections.findIndex(s =>
                    s.sectionName === sectionToMarkAsSubmitted.sectionName &&
                    s.type === sectionToMarkAsSubmitted.type
                );

                if (sectionIndex !== -1) {
                    formBeingUpdated.form.sections[sectionIndex] = {
                        ...formBeingUpdated.form.sections[sectionIndex],
                        submitted: submissionStatus,
                        submissions: sectionToMarkAsSubmitted.submissions
                    };
                }
            }

            console.log("Updated submissions:", sectionToMarkAsSubmitted);
            dispatch(addUserSubmittedTask(userDashboard.loadedTask));

            dispatch(patchSubmissionOfSection({
                form: formBeingUpdated,
                section: sectionToMarkAsSubmitted,
                _id: formBeingUpdated._id
            }));
        }


        setTimeout(() => {
            dispatch(setPageView(""))
            dispatch(fetchUserSubmittedTask({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition._id }))
            dispatch(initializeJobsAndLoadTasks({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getFormSectionsAssignedToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getFormSectionsReportingToActiveUser({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))
            dispatch(getTrackedTasksReq({ org_id: user.org_id, viewingLocation: user?.viewingLocation, user_id: user._id, job_id: user.jobPosition }))

            window.location.reload()

        }, 3000);




    }

    function updatingResponseField(value, index1, index2, index3) {
        console.log("updatingCheckboxField  :", value, index1, index2, index3)


        // Find section index using section._id instead of sectionName for more reliable matching
        const findSectionIndex = (sections, targetSection) => {
            if (!sections || !Array.isArray(sections)) {
                return -1;
            }
            return sections.findIndex(section =>
                section.sectionName === targetSection.sectionName &&
                section.type === targetSection.type
            );
        };

        const sectionIndex = findSectionIndex(
            userDashboard.loadedTask.task.form.sections,
            userDashboard.loadedTask.section
        );

        // Guard against invalid section index
        if (sectionIndex === -1) {
            console.warn("Section not found", {
                sections: userDashboard.loadedTask.task.form.sections,
                targetSection: userDashboard.loadedTask.section
            });
            return;
        }



        dispatch(updateResponseField({ value, index1, index2, index3, sectionIndex }))

    }

    function updatingCheckboxField(evt, index1, index2) {
        // Early return if required data is missing
        if (!userDashboard?.loadedTask?.task?.form.sections || !userDashboard?.loadedTask?.section) {
            console.warn("Required data is missing");
            return;
        }


        // Find section index using section._id instead of sectionName for more reliable matching
        const findSectionIndex = (sections, targetSection) => {
            if (!sections || !Array.isArray(sections)) {
                return -1;
            }
            return sections.findIndex(section =>
                section.sectionName === targetSection.sectionName &&
                section.type === targetSection.type
            );
        };

        const sectionIndex = findSectionIndex(
            userDashboard.loadedTask.task.form.sections,
            userDashboard.loadedTask.section
        );

        // Guard against invalid section index
        if (sectionIndex === -1) {
            console.warn("Section not found", {
                sections: userDashboard.loadedTask.task.form.sections,
                targetSection: userDashboard.loadedTask.section
            });
            return;
        }


        //  let sectionIndex = findSectionIndex(userDashboard?.loadedTask.task.sections, userDashboard?.loadedTask?.section.sectionName)



        if (userDashboard?.loadedTask?.section.fields[index1]?.answerChoicesResponse && evt == userDashboard?.loadedTask?.section.fields[index1]?.answerChoicesResponse) {
            dispatch(updateCheckboxField({ value: '', index1, sectionIndex }))

        }
        else {
            dispatch(updateCheckboxField({ value: evt, index1, sectionIndex }))

        }


    }


    function updatingQuantityField(evt, index1) {
        console.log("updateQuantityField  :", evt.target.value, index1,)


        dispatch(updateQuantityField({ value: evt.target.value, index1 }))

    }


    function openTask(task, type, section) {
        console.log("Opening task :", task)


        dispatch(setTask({ task: task.form, type: type, section: section, schedule: task.schedule }))

    }

    function onDeleteClicked(item) {
        console.log("deleting:", item)
        dispatch(deleteItem(item))

        clearDataFromFields()
    }


    function onCancelClicked() {
        //should clear selected states too todo
        dispatch(setTask({}))
        dispatch(setPageView(""))

    }

    function onSaveClicked() {


        if (loadedItem._id) {
            dispatch(patchItem({
                displayName: document.getElementById('displayName').value,
                vendor: loadedVendor, //must match collection name in firebase 'Vendors'
                cost: document.getElementById('Cost').value,
                createdAt: document.getElementById('Date').value,
                dateOfCost: document.getElementById('Date').value,
                itemAmount: document.getElementById('displayName').value,
                itemName: document.getElementById('displayName').value,
                par: document.getElementById('Par').value,
                pendingOrderQuantity: document.getElementById('displayName').value,
                size: document.getElementById('Size').value,
                itemNumber: document.getElementById("itemNumber").value,
                reportingGroup: loadedRG,
                _id: loadedItem._id
            }))
        }
        else {
            dispatch(addNewItem({
                displayName: document.getElementById('displayName').value,
                vendor: loadedVendor, //must match collection name in firebase 'Vendors'
                cost: document.getElementById('Cost').value,
                createdAt: document.getElementById('Date').value,
                dateOfCost: document.getElementById('Date').value,
                itemAmount: document.getElementById('displayName').value,
                itemName: document.getElementById('displayName').value,
                par: document.getElementById('Par').value,
                pendingOrderQuantity: document.getElementById('displayName').value,
                size: document.getElementById('Size').value,
                itemNumber: document.getElementById("itemNumber").value,
                reportingGroup: loadedRG,

            }))
        }

        dispatch(fetchItems())

    }

    function clearDataFromFields() {
        document.getElementById('displayName').value = '',
            document.getElementById('Cost').value = '',
            document.getElementById('Date').value = '',
            document.getElementById('Par').value = '',
            document.getElementById('Size').value = '',
            document.getElementById("itemNumber").value = ''
    }




}





export default UserDashboard;
