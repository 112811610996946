import styles from './Calendar.css';
import React from 'react';
import { FaSearch } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";

import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from 'react-redux';
import { getHtmlListLoading, setViewDate, getViewDate, setCurrentHelperList2, setLoadStatus, getLoadStatus, selectCalendar, setNumdaysInThisMonth, setCurrentYear, setCurrentMonthVal, setNumdaysPreviousMonth, setNumdaysInPreviousMonthToShow, getNumdaysInThisMonth, getNumdaysInPreviousMonth, getNumdaysInPreviousMonthToShow, getHelperList1, getHelperList2, getCurrentMonth, getCurrentYear, setCurrentHelperList1, } from './CalendarSlice'


const deviceDetection = {
    // Check if device is mobile (including tablets)
    isMobile: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            // Include tablets in mobile detection
            /Tablet|iPad/i.test(navigator.userAgent);
    },

    // Check if device is specifically a tablet
    isTablet: () => {
        return /Tablet|iPad/i.test(navigator.userAgent) ||
            // Alternative method using screen size
            (deviceDetection.isMobile() && Math.min(window.screen.width, window.screen.height) >= 768);
    },

    // Check if device is specifically a smartphone
    isSmartphone: () => {
        return deviceDetection.isMobile() && !deviceDetection.isTablet();
    },

    // Check if device is desktop
    isDesktop: () => {
        return !deviceDetection.isMobile();
    },

    // Check orientation
    isLandscape: () => {
        // Primary method using window.matchMedia
        const mediaQuery = window.matchMedia('(orientation: landscape)');

        // Fallback to window dimensions if matchMedia is not supported
        if (!mediaQuery.matches && window.screen) {
            return window.screen.width > window.screen.height;
        }

        return mediaQuery.matches;
    },

    // Get device type and orientation state
    getDeviceState: () => {
        return {
            isMobile: deviceDetection.isMobile(),
            isTablet: deviceDetection.isTablet(),
            isSmartphone: deviceDetection.isSmartphone(),
            isDesktop: deviceDetection.isDesktop(),
            isLandscape: deviceDetection.isLandscape()
        };
    }
};


const isLandscape = window.matchMedia(`
  screen and 
  (min-width: 1024px) and 
  (max-height: 768px) and 
`).matches;


function Calendar(props) {

    const deviceState = deviceDetection.getDeviceState();

    const [data, setData] = useState("");
    // console.log("Checking cal: ", props,)

    //document.getElementById("mainCal").style.left = props.dataFromParent.left
    // document.getElementById("mainCal2").style.left = props.dataFromParent.left
    const startDate = useSelector(
        (state) => state.forms.formBeingMade.sections[props.sectionIndex]?.startDate
    );

    useEffect(() => {
        console.log('Start date changed:', startDate);
    }, [startDate]);


    let MonthMap = {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December",
    }

    let isDisplayed = false;
    const dispatch = useDispatch();

    const htmlHelperList = useSelector(getHelperList1);
    const htmlHelperList2 = useSelector(getHelperList2);

    const currentMonth = useSelector(getCurrentMonth);
    const currentYear = useSelector(getCurrentYear);

    const NumdaysInPreviousMonthToShow = useSelector(getNumdaysInPreviousMonthToShow);
    const NumdaysInPreviousMonth = useSelector(getNumdaysInPreviousMonth);
    const NumdaysInThisMonth = useSelector(getNumdaysInThisMonth);

    const loadingHtml = useSelector(getHtmlListLoading);

    const loading = useSelector(getLoadStatus);
    const viewDate = useSelector(getViewDate);
    const [previousSelectedDay, setPreviousSelectedDay] = useState();


    // Use the startDate value as the initial value for the inputValue state
    const [inputValue, setInputValue] = useState(startDate || '');


    // console.log("Pulled current month : ", useSelector(selectCalendar))

    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    useEffect(() => {
        console.log('Start date changed:', startDate);
        if (loading !== 'Done') {
            initCalendar(viewDate, currentMonth, currentYear);
        }
        setInputValue(startDate);
    }, [loading, loadingHtml, dispatch, startDate]);


    return (
        <>

            {props.dataFromParent.displayType == 'Calendar' && deviceState.isMobile == false &&

                <div id="mainCal" className="mainCal" style={{ ...props?.dataFromParent?.style?.calendar, zIndex: 200002 }}>

                    <div className="popupBtn">

                        <div className="toolbar">
                            <button onClick={(evt) => { prevMonth() }} className="prevbutton" >Previous</button>
                            <span className="monthTitle" >{MonthMap[currentMonth]} </span>
                            <button onClick={(evt) => { nextMonth() }} className="nextButton" >Next</button>
                        </div >

                    </div >


                    <div className="mainCalendar" >

                        <div className="headerRow" >
                            {
                                htmlHelperList2.map((col, j) => {
                                    return (
                                        <div id={"headerCell" + j} className="headerCell">
                                            <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', }}>
                                                {days[j]}
                                            </span>
                                        </div>)
                                })

                            }
                        </div>

                        <div style={{ cursor: 'pointer' }}>
                            {loadingHtml == 'Done' && htmlHelperList.map((row, i) => {
                                return (
                                    <div className="calDateRow" id="calDateRow">{
                                        htmlHelperList2.map((col, j) => {
                                            return (

                                                <div className="CaldayCell"
                                                    id={'CaldayCell' + i + j}


                                                    style={(() => {
                                                        const isFirstWeekPreviousMonth = j < NumdaysInPreviousMonthToShow && i == 0;
                                                        const isNextMonth = j + (i * 7) - NumdaysInPreviousMonthToShow > NumdaysInThisMonth;
                                                        const isPastDate =
                                                            (currentMonth < new Date().getMonth() && currentYear <= new Date().getFullYear()) ||
                                                            (currentMonth === new Date().getMonth() && currentYear === new Date().getFullYear() &&
                                                                (isFirstWeekPreviousMonth
                                                                    ? NumdaysInPreviousMonth - (NumdaysInPreviousMonthToShow - j) + 1
                                                                    : isNextMonth
                                                                        ? j + (i * 7) - (NumdaysInThisMonth + NumdaysInPreviousMonthToShow) + 1
                                                                        : j + (i * 7) - NumdaysInPreviousMonthToShow + 1) < new Date().getDate());


                                                        if (previousSelectedDay == 'CaldayCell' + i + j) {
                                                            return { backgroundColor: 'red' };

                                                        }
                                                        else {
                                                            if (isFirstWeekPreviousMonth || isNextMonth || isPastDate) {
                                                                return { backgroundColor: '#9b9b9b' };
                                                            } else {
                                                                return { backgroundColor: 'white' };
                                                            }
                                                        }

                                                    })()}
                                                    onClick={(evt) => { setTextColor('CaldayCell' + i + j, i, j) }}
                                                >
                                                    <div>
                                                        {(() => {
                                                            const isFirstWeekPreviousMonth = j < NumdaysInPreviousMonthToShow && i == 0;
                                                            const isNextMonth = j + (i * 7) - NumdaysInPreviousMonthToShow > NumdaysInThisMonth;
                                                            const dayOffset = j + (i * 7);

                                                            if (isFirstWeekPreviousMonth) {
                                                                return Math.floor(NumdaysInPreviousMonth - (NumdaysInPreviousMonthToShow - j) + 1);
                                                            } else if (isNextMonth) {
                                                                return Math.floor(dayOffset - (NumdaysInThisMonth + NumdaysInPreviousMonthToShow) + 1);
                                                            } else {
                                                                return Math.floor(dayOffset - NumdaysInPreviousMonthToShow + 1);
                                                            }
                                                        })()}
                                                    </div>

                                                </div>)
                                        })}
                                    </div>)
                            })}
                            <button onClick={(evt) => { setOutput(); props.dataFromParent.setDisplayType('Input') }} className={styles.closeBtn} style={{ float: 'left' }} >Close</button>

                            <button onClick={() => { setOutput(); props.dataFromParent.setDisplayType('Input') }} className={styles.doneBtn} style={{ float: 'right' }}>Done</button>

                        </div>

                    </div >
                </div>

            }


            <div id="mainCal2" className="mainCal2" style={{ zIndex: 'auto' }} >
                <input placeholder={props?.dataFromParent?.placeholder} value={new Date(props.initialValue).toLocaleDateString()} type={deviceState.isMobile ? 'date' : 'none'} defaultValue={new Date(props.initialValue).toLocaleDateString()} style={{ ...props?.dataFromParent?.style?.input }} onClick={(evt) => { props.dataFromParent.setDisplayType('Calendar'); }} />


            
            

            </div>



        </>
    );

    function initCalendar(incomingDay, month, year) {
        //   viewDate = incomingDay

        //    console.log("initCalendar check : ", incomingDay, month, year)


        dispatch(setCurrentYear(year))
        dispatch(setNumdaysInThisMonth({ month: month + 1, year }))

        //  dispatch(setNumdaysInThisMonth(month))
        dispatch(setNumdaysInPreviousMonthToShow(month))
        dispatch(setNumdaysPreviousMonth({ month: month, year }))


        dispatch(setViewDate(incomingDay))
        dispatch(setCurrentMonthVal(month))


        // this.NumdaysInThisMonth = 32 - new Date(incomingDay.getYear(), incomingDay.getMonth(), 32).getDate();
        //  this.NumdaysInPreviousMonthToShow = 6 - (6 - (new Date(incomingDay.getYear(), incomingDay.getMonth())).getDay())


        //   let daysInPreviousMonth = 32 - new Date(this.currentYear, this.currentMonth - 1, 32).getDate();
        //   this.NumdaysInPreviousMonth = daysInPreviousMonth


        dispatch(setLoadStatus('Done'))




    }

    function prevMonth() {
        //  console.log("PREVIOUS MONTH CLICKED  the current month is : ", currentMonth)
        let prevMonth = currentMonth - 1;
        let nextYear = currentYear

        if (prevMonth == -1) {
            nextYear = currentYear - 1
            prevMonth = 11;
        }
        let incomingDay = new Date()
        incomingDay.setMonth(prevMonth)
        incomingDay.setFullYear(currentYear)
        //   console.log("PREVIOUS MONTH CLICKED  the next month is : ", prevMonth)

        //    console.log("New start date coming in : ", incomingDay.getDate(), prevMonth, nextYear)
        // initCalendar(newStartDate.getDate(), nextMonth, nextYear)

        //  console.log("Checking numbers to show before : ", NumdaysInPreviousMonthToShow, NumdaysInThisMonth, NumdaysInPreviousMonth)

        dispatch(setLoadStatus(false))

        dispatch(setCurrentYear(nextYear))

        //    console.log("PREVIOUS MONTH CLICKED sending to functions : ", prevMonth,)

        // console.log("Checking numbers to show, Numbers should be : ", 6 - (6 - (incomingDay.getDate())), 32 - new Date(nextYear, nextMonth, 32).getDate(), 32 - new Date(nextYear, nextMonth - 1, 32).getDate(),)
        //dispatch(setNumdaysInThisMonth({ month: prevMonth, year: nextYear }))
        //dispatch(setNumdaysInPreviousMonthToShow(prevMonth - 1))
        //  dispatch(setNumdaysPreviousMonth({ month: prevMonth - 1, year: nextYear }))


        dispatch(setViewDate(incomingDay.getDate()))
        dispatch(setCurrentMonthVal(prevMonth))



        //dispatch(setCurrentHelperList1())

        // console.log("Checking numbers to show after : ", NumdaysInPreviousMonthToShow, NumdaysInThisMonth, NumdaysInPreviousMonth)



    }




    function setOutput() {
        //This can lead to bug (select day, change months, then close)
        let output;
        output = new Date()

        //  console.log("set output reached")

        if (previousSelectedDay) {
            output.setDate(document.getElementById(previousSelectedDay).innerText)
            output.setMonth(currentMonth)
            output.setFullYear(currentYear)
        }
        setInputValue(output.toDateString())

        //   console.log("trying to call props?.dataFromParent.callBackin set output : ",)

        console.log("Setting output : ",  output.toDateString())

        props?.dataFromParent.callBack(output.toDateString(), props.sectionIndex, props.reportIndex)
    }



    function setTextColor(dayCellId, i, j) {



        if (
            (j < NumdaysInPreviousMonthToShow && i === 0) ||
            (j + (i * 7) - NumdaysInPreviousMonthToShow >= NumdaysInThisMonth) ||
            (currentMonth < new Date().getMonth() && currentYear <= new Date().getFullYear()) ||
            (currentMonth === new Date().getMonth() && currentYear === new Date().getFullYear() &&
                (j + (i * 7) - NumdaysInPreviousMonthToShow + 1 < new Date().getDate())
            )
        ) {
            //   document.getElementById(previousSelectedDay).style.backgroundColor = "grey"
            //  console.log("color box grey :"  , i ,j )
        }
        else {


            if (previousSelectedDay) {
                document.getElementById(previousSelectedDay).style.backgroundColor = "white"
            }

            document.getElementById(dayCellId).style.backgroundColor = "red"

            if (previousSelectedDay == dayCellId) {
                //    console.log("Day locked in : ", document.getElementById(dayCellId).innerText, document.getElementById(dayCellId))

            /*    let output;
                output = new Date()
                output.setDate(document.getElementById(dayCellId).innerText)
                output.setMonth(currentMonth)
                output.setFullYear(currentYear)


                setInputValue(output.toDateString())
                props.dataFromParent.setDisplayType('Input')

                //     console.log("trying to call props?.dataFromParent.callBack in set output : ",)
                props?.dataFromParent.callBack(output.toDateString(), props.sectionIndex, props.reportIndex)*/
                setOutput()

            }

            console.log("Setting selected day : ", dayCellId)
            setPreviousSelectedDay(dayCellId)
        }




    }


    function nextMonth() {

        //  console.log("Current month before update ", currentMonth)
        let nextYear = currentYear

        let nextMonth = currentMonth + 1;
        if (nextMonth == 12) {
            nextYear = currentYear + 1
            nextMonth = 0;
        }
        let incomingDay = new Date()
        incomingDay.setMonth(nextMonth)
        incomingDay.setFullYear(nextYear)

        // console.log("New start date coming in : ", incomingDay.getDate(), nextMonth, nextYear)
        dispatch(setLoadStatus(false))

        dispatch(setCurrentYear(nextYear))

        dispatch(setNumdaysInThisMonth(nextMonth))
        dispatch(setNumdaysInPreviousMonthToShow(nextMonth))
        dispatch(setNumdaysPreviousMonth(nextMonth))


        dispatch(setViewDate(incomingDay.getDate()))
        dispatch(setCurrentMonthVal(nextMonth))



    }

    function createHtmlMap() {
        //  this.dateToHtmlMap = {}
        //     this.insertTaskMap = {}

        for (let i = 0; i < htmlHelperList.length; i++) {
            for (let j = 0; j < htmlHelperList2.length; j++) {

                let dayvalue = (j < NumdaysInPreviousMonthToShow && i == 0) || j + (i * 7) - NumdaysInPreviousMonthToShow > NumdaysInThisMonth - 1 ? i == 0 ? NumdaysInPreviousMonth - (NumdaysInPreviousMonthToShow - j) + 1 : j + (i * 7) - (NumdaysInThisMonth + NumdaysInPreviousMonthToShow) + 1 : j + (i * 7) - NumdaysInPreviousMonthToShow + 1
                let monthvalue
                let yearvalue

                if ((j < NumdaysInPreviousMonthToShow && i == 0) || ((j + (i * 7) - NumdaysInPreviousMonthToShow) > (NumdaysInThisMonth - 1))) {
                    if (i == 0) {
                        if (currentMonth != 0) {
                            monthvalue = currentMonth - 1
                            yearvalue = currentYear
                        }
                        else {
                            //case current month is january, try to subtract one.
                            monthvalue = 11
                            yearvalue = Number(currentYear) - 1
                            //  console.log("Previous year")
                        }
                    }
                    else {
                        if (currentMonth != 11) {
                            monthvalue = currentMonth + 1
                            //   console.log("Setting thing to current year : " , currentYear)
                            yearvalue = currentYear
                        }
                        else {
                            //case current month is december, try to add one.
                            monthvalue = 0
                            yearvalue = Number(currentYear) + 1
                            //  console.log("Next year")
                        }
                    }
                }
                else {
                    monthvalue = currentMonth
                    yearvalue = currentYear
                }

                let tempDatekey = i + "," + j
                let CaldayCell = document.getElementById("CaldayCell" + i + j)
                let nextIndex = getNextIndex(i, j, htmlHelperList.length, htmlHelperList2.length)
                let nextCaldayCell = document.getElementById("CaldayCell" + nextIndex[0] + nextIndex[1])

                monthvalue++;

                //     insertTaskMap[monthvalue + "/" + dayvalue + "/" + yearvalue] = tempDatekey
                //   dateToHtmlMap[tempDatekey] = { Html: CaldayCell, NextDay: nextCaldayCell, realDate: monthvalue + "/" + dayvalue + "/" + yearvalue, nextDate: dateValueFromHTMLIndex(nextIndex[0], nextIndex[1]), Selected: false, ScreenIndex: [i, j] }
            }
        }

        //  this.mapLoaded = true;
    }
}



export default Calendar;
